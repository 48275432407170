import { CURRENTDATE } from "../app-constants";

export const ProjectmspDynamicJSON =[// PROJECT INITIATION step in Mammalian Cell Lines Stock Preparation
 {
    jsonId: 94,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_576',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_790',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'project_title',
                id: 'project_title',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'initial-submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Project Title',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_50',
            classname: 'col-12 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_808',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'dropdown',
                name: 'project_init_by',
                id: 'project_init_by',
                validationButtonType: 'initial-submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'get',
                    url: 'user_management/users_list',
                  },
                ],
                // options: [
                //   {
                //     id: 1,
                //     value: 'admin',
                //   },
                // ],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Initiated By',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_290',
            classname: 'col-12 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_654',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'project_start_date',
                id: 'project_start_date',
                validationButtonType: 'initial-submit',
                range: false,
                value: '',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: '',
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                // regularExpression:
                //   '/^(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4}(s+tos+(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4}|(,s*(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4})*)?$/',
                required: true,
                classname: '',
                errorMessage: { noData: 'This field is required', regexFail: 'Date is not valid!!' },
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: '',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'Start Date',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_209',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_778',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'project_end_date',
                id: 'project_end_date',
                validationButtonType: '',
                range: false,
                value: '',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: CURRENTDATE,
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                // regularExpression:
                //   '/^(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4}(s+tos+(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4}|(,s*(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4})*)?$/',
                required: false,
                classname: '',
                errorMessage: { noData: 'This field is required', regexFail: 'Date is not valid!!' },
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: '',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'End Date',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_643',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_92',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: 'form-container',
  },

  // CELL LINE INFORMATION  step in Mammalian Cell Lines Stock Preparation
  {
    jsonId: 95,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_73',
        classname: '',
        wrapperType: 'BOX',
        id: 'wrapper_364',
        fields: [
          {
            control: [],
            type: '',
            name: 'cell_line_table',
            classname: 'table project-ini-table',
            wrapperType: 'TABLE',
            id: 'cell_line_table',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            tableFields: {
              showHead: true,
              isVertical: false,
              isIteratable: false,
              addButton: {
                classname: 'add-expand',
                name: '',
                value: '<div class="custom-tooltip"><p>Add Row</div>',
              },
              removeButton: {
                classname: 'add-minus',
                name: '',
                value: '<div class="custom-tooltip"><p>Remove Row</div>',
              },
              tableHead: {
                heading: [
                  { value: 'CELL LINE', classname: '' },
                  { value: 'CELL LINE ID', classname: '' },
                  { value: 'SOURCE', classname: '' },
                  { value: 'MAMMALIAN RESISTANCE', classname: '' },
                  { value: 'STORAGE LOCATION', classname: '' },
                  // { value: 'ACTIONS', classname: '' },
                ],
                classname: '',
              },
              initRecordCount: 1,
              showRecordCount: false,
              recordCounterConfig: { classname: '', adjValue: '', isAdjValueAfter: false },
              tableRow: {
                name: '',
                classname: 'trclass',
                tableCols: [
                  {
                    fields: {
                      control: [
                        {
                          required: true,
                          validationButtonType: 'plasmid-submit',
                          type: 'dropdown',
                          name: 'cell_line',
                          id: 'cell_line',
                          classname: '',
                          apiEndPoints: [
                            {
                              type: 'init',
                              method: 'post',
                              url: 'inventory_item',
                              payload: { user_input: '', perma: 'cell-lines' },
                            },
                            {
                              type: 'change',
                              method: 'get',
                              isGet: true,
                              Mapto: [
                                {
                                  id: 'main.wrapper_364.cell_line_table.${i}.box_716.cell_line_id',
                                  displayKey: 'change',
                                  urlParameterValues: { id: null, patchProperty: 'inv_item_id' },
                                },
                                {
                                  id: 'main.wrapper_364.cell_line_table.${i}.box_716.source',
                                  displayKey: 'change',
                                  urlParameterValues: { id: null, patchProperty: 'source' },
                                },
                                {
                                  id: 'main.wrapper_364.cell_line_table.${i}.box_716.mammalian_resistance',
                                  displayKey: 'change',
                                  urlParameterValues: { id: null, patchProperty: 'mammalian_resistance.value' },
                                },
                                {
                                  id: 'main.wrapper_364.cell_line_table.${i}.box_716.cell_line_info',
                                  displayKey: 'change',
                                  urlParameterValues: { id: null, patchProperty: 'cell_line_info' },
                                },
                              ],
                              url: 'inventory-celllines/${id}',
                            },
                          ],
                          options: [],
                          multiple: false,
                          isClearable: true,
                          isStatus: false,
                          search: true,
                          singleSelect: true,
                          showLabel: 'value',
                          disabled: false,
                          wrapperClassName: '',
                          connectedArrays: [],
                          showBlankInPlaceholder: false,
                          blankDataList: [],
                          showInputLabel: true,
                          labelContent: '',
                          labelClass: 'form-label d-none',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                          errorMessage: {
                            noData: 'This field is required',
                          },
                        },
                      ],
                      name: 'groupDetails',
                      classname: '',
                      id: 'Group_Details',
                      wrapperType: 'BOX',
                      fields: [],
                    },
                  },
                  {
                    fields: {
                      control: [
                        {
                          type: 'text',
                          name: 'cell_line_id',
                          id: 'cell_line_id',
                          value: '-',
                          placeholder: '',
                          multiline: false,
                          classname: 'form-control ',
                          regularExpression: '',
                          disabled: true,
                          required: false,
                          errorMessage: {
                            noData: 'This field is required',
                            regexFail: '',
                          },
                          validationButtonType: '',
                          rows: 0,
                          columns: 0,
                          onDemandError: false,
                          customValidation: [],
                          multiError: false,
                          applicationError: '',
                          wrapperClassName: '',
                          requiredErrorMessage: 'This field is required',
                          showInputLabel: true,
                          labelContent: '',
                          labelClass: 'form-label',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                        {
                          type: 'text',
                          name: 'cell_line_info',
                          id: 'cell_line_info',
                          value: '',
                          placeholder: '',
                          multiline: false,
                          classname: 'd-none',
                          regularExpression: '',
                          disabled: true,
                          required: false,
                          errorMessage: {
                            noData: 'This field is required',
                            regexFail: '',
                          },
                          validationButtonType: '',
                          rows: 0,
                          columns: 0,
                          onDemandError: false,
                          customValidation: [],
                          multiError: false,
                          applicationError: '',
                          wrapperClassName: '',
                          requiredErrorMessage: 'This field is required',
                          showInputLabel: true,
                          labelContent: '',
                          labelClass: 'd-none',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                        {
                          type: 'text',
                          name: 'prj_cell_con_id',
                          id: 'prj_cell_con_id',
                          value: '',
                          placeholder: '',
                          multiline: false,
                          classname: 'd-none',
                          regularExpression: '',
                          disabled: true,
                          required: false,
                          errorMessage: {
                            noData: 'This field is required',
                            regexFail: '',
                          },
                          validationButtonType: '',
                          rows: 0,
                          columns: 0,
                          onDemandError: false,
                          customValidation: [],
                          multiError: false,
                          applicationError: '',
                          wrapperClassName: '',
                          requiredErrorMessage: 'This field is required',
                          showInputLabel: true,
                          labelContent: '',
                          labelClass: 'd-none',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                      ],

                      name: 'box_644',
                      id: 'box_716',
                      wrapperType: 'BOX',
                      fields: [],
                      classname: '',
                      addButton: { classname: '', value: '' },
                      removeButton: { classname: '', value: '' },
                      wrapperClassName: '',
                    },
                    classname: '',
                  },
                  {
                    fields: {
                      control: [
                        {
                          type: 'text',
                          name: 'source',
                          id: 'source',
                          value: '-',
                          placeholder: '',
                          multiline: false,
                          classname: 'form-control ',
                          regularExpression: '',
                          disabled: true,
                          required: false,
                          errorMessage: {
                            noData: 'This field is required',
                            regexFail: '',
                          },
                          validationButtonType: '',
                          rows: 0,
                          columns: 0,
                          onDemandError: false,
                          customValidation: [],
                          multiError: false,
                          applicationError: '',
                          wrapperClassName: '',
                          requiredErrorMessage: 'This field is required',
                          showInputLabel: true,
                          labelContent: '',
                          labelClass: 'form-label',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                      ],
                      name: 'box_644',
                      id: 'box_716',
                      wrapperType: 'BOX',
                      fields: [],
                      classname: '',
                      addButton: { classname: '', value: '' },
                      removeButton: { classname: '', value: '' },
                      wrapperClassName: '',
                    },
                    classname: '',
                  },
                  {
                    fields: {
                      control: [
                        {
                          type: 'text',
                          name: 'mammalian_resistance',
                          id: 'mammalian_resistance',
                          value: '-',
                          placeholder: '',
                          multiline: false,
                          classname: 'form-control ',
                          regularExpression: '',
                          disabled: true,
                          required: false,
                          errorMessage: {
                            noData: 'This field is required',
                            regexFail: '',
                          },
                          validationButtonType: '',
                          rows: 0,
                          columns: 0,
                          onDemandError: false,
                          customValidation: [],
                          multiError: false,
                          applicationError: '',
                          wrapperClassName: '',
                          requiredErrorMessage: 'This field is required',
                          showInputLabel: true,
                          labelContent: '',
                          labelClass: 'form-label',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                      ],
                      name: 'box_644',
                      id: 'box_716',
                      wrapperType: 'BOX',
                      fields: [],
                      classname: '',
                      addButton: { classname: '', value: '' },
                      removeButton: { classname: '', value: '' },
                      wrapperClassName: '',
                    },
                    classname: '',
                  },
                  {
                    fields: {
                      control: [
                        {
                          type: 'button',
                          id: 'infoicon',
                          value: 'View Location',
                          buttonType: 'cell_storage_info',
                          classname: 'info-icon',
                          divClass: '',
                          controlEndPoints: [],
                          disabled: false,
                          validationEndPoints: [],
                          invalid: false,
                          name: 'infoicon',
                          wrapperClassName: '',
                          draggable: false,
                        },
                      ],
                      name: 'box_644',
                      id: 'box_716',
                      wrapperType: 'BOX',
                      fields: [],
                      classname: '',
                      addButton: { classname: '', value: '' },
                      removeButton: { classname: '', value: '' },
                      wrapperClassName: '',
                    },
                    classname: '',
                  },
                ],
              },
            },
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_133',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_329',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'exprmntal_notes',
                id: 'exprmntal_notes',
                value: '',
                placeholder: '',
                multiline: true,
                classname: 'form-control textarea',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Experimental Notes/Comments',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_588',
            classname: 'col-12 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_345',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  //mammalian cell line stock preparation
  {
    jsonId: 96,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_871',
        classname: 'row mb-4',
        wrapperType: 'BOX',
        id: 'wrapper_17',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
                name: 'protocol',
                id: 'protocol',
                validationButtonType: 'MCLP-submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'get',
                    url: 'protocol/protocol-list',
                  },
                  {
                    type: 'change',
                    method: 'get',
                    Mapto: [
                      {
                        id: '',
                        displayKey: 'value',
                        urlParameterValues: { id: null },
                      },
                    ],
                    url: 'protocol/${id}',
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: {
                  noData: 'This field is required',
                },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                connectedArrays: [],
                showBlankInPlaceholder: false,
                blankDataList: [],
                showInputLabel: true,
                labelContent: 'Protocol',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_3922',
            classname: 'col-3',
            wrapperType: 'BOX',
            id: 'wrapper_6250',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'button',
                id: 'button_566',
                value: '<div class="custom-tooltip"><p>View</p></div>',
                buttonType: 'protocol_info',
                classname: 'view',
                divClass: '',
                controlEndPoints: [],
                disabled: false,
                validationEndPoints: [],
                invalid: false,
                name: 'button_779',
                wrapperClassName: 'actions position-relative',
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_861',
            classname: 'col-1 mt-4 pt-2',
            wrapperType: 'BOX',
            id: 'wrapper_41',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        head: '',
        name: 'main',
        id: 'main',
        wrapperType: 'BOX',
        fields: [
          {
            control: [],
            type: '',
            name: 'wrapper_647',
            classname: 'row',
            wrapperType: 'BOX',
            id: 'wrapper_276',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_983',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_156',
        fields: [
          {
            control: [
              {
                type: 'label',
                name: 'Cell Line Info & Preparation',
                classname: '',
                for: '',
                required: false,
                wrapperClassName: '',
                id: 'label_432',
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_95',
            classname: 'col-12  add-heading heading-without-icon ',
            wrapperType: 'BOX',
            id: 'wrapper_276',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
      },
      {
        control: [],
        head: '',
        name: 'main',
        id: 'main',
        wrapperType: 'BOX',
        fields: [
          {
            control: [],
            type: '',
            name: 'wrapper_647',
            classname: 'row',
            wrapperType: 'BOX',
            id: 'wrapper_556',
            fields: [
              {
                control: [
                  {
                    type: 'text',
                    name: 'mammalian_cell_line',
                    id: 'mammalian_cell_line',
                    value: '',
                    placeholder: '',
                    multiline: false,
                    classname: 'form-control ',
                    regularExpression: '',
                    disabled: true,
                    required: true,
                    errorMessage: {
                      noData: 'This fields is required',
                      regexFail: '',
                    },
                    validationButtonType: 'MCLP-submit',
                    rows: 0,
                    columns: 0,
                    onDemandError: false,
                    customValidation: [],
                    multiError: false,
                    applicationError: '',
                    wrapperClassName: '',
                    requiredErrorMessage: 'This fields is required',
                    showInputLabel: true,
                    labelContent: 'Mammalian  Cell Line',
                    labelClass: 'form-label',
                    isLabelAfter: false,
                    showAdditionalInputContent: false,
                    additionalInputContent: '',
                    isAdditonalInputAfter: true,
                    draggable: false,
                  },
                ],
                type: '',
                name: 'wrapper_706',
                classname: 'col-3 mb-3',
                wrapperType: 'BOX',
                id: 'wrapper_500',
                fields: [],
                head: '',
                tabHeadClass: '',
              },
              {
                control: [
                  {
                    type: 'dropdown',
                    name: 'growth_media',
                    id: 'growth_media',
                    validationButtonType: 'MCLP-submit',
                    classname: '',
                    required: true,
                    apiEndPoints: [
                      {
                        type: 'init',
                        method: 'post',
                        url: 'inventory_item',
                        payload: {
                          user_input: '',
                          perma: 'cell-culture-media',
                        },
                      },
                    ],
                    options: [],
                    multiple: false,
                    isClearable: false,
                    isStatus: false,
                    search: false,
                    singleSelect: true,
                    showLabel: 'value',
                    errorMessage: {
                      noData: 'This fields is required',
                    },
                    value: '',
                    imageUrl: '',
                    disabled: false,
                    wrapperClassName: '',
                    connectedArrays: [],
                    showBlankInPlaceholder: false,
                    blankDataList: [],
                    showInputLabel: true,
                    labelContent: 'Growth  Media',
                    labelClass: 'form-label',
                    isLabelAfter: false,
                    showAdditionalInputContent: false,
                    additionalInputContent: '',
                    isAdditonalInputAfter: true,
                    draggable: false,
                  },
                ],
                type: '',
                name: 'wrapper_223',
                classname: 'col-3 mb-3',
                wrapperType: 'BOX',
                id: 'wrapper_601',
                fields: [],
                head: '',
                tabHeadClass: '',
              },
              {
                control: [
                  {
                    type: 'text',
                    name: 'cell_count',
                    id: 'cell_count',
                    value: '',
                    placeholder: '',
                    multiline: false,
                    classname: 'form-control ',
                    regularExpression: '',
                    disabled: false,
                    required: true,
                    errorMessage: {
                      noData: 'This fields is required',
                      regexFail: '',
                    },
                    validationButtonType: 'MCLP-submit',
                    rows: 0,
                    columns: 0,
                    onDemandError: false,
                    customValidation: [{ regularExpression: "/[.\d*^]+/", errorMessage: 'please enter  valid data' }],
                    multiError: false,
                    applicationError: '',
                    wrapperClassName: '',
                    requiredErrorMessage: 'This fields is required',
                    showInputLabel: true,
                    labelContent: 'Cell Count  <span>(millions/mL)</span>',
                    labelClass: 'form-label',
                    isLabelAfter: false,
                    showAdditionalInputContent: false,
                    additionalInputContent: '',
                    isAdditonalInputAfter: true,
                    draggable: false,
                  },
                ],
                type: '',
                name: 'wrapper_9',
                classname: 'col-3 mb-3',
                wrapperType: 'BOX',
                id: 'wrapper_298',
                fields: [],
                head: '',
                tabHeadClass: '',
              },
              {
                control: [
                  {
                    type: 'text',
                    name: 'cell_viabiality',
                    id: 'cell_viabiality',
                    value: '',
                    placeholder: '',
                    multiline: false,
                    classname: 'form-control ',
                    regularExpression: '',
                    disabled: false,
                    required: true,
                    errorMessage: {
                      noData: 'This fields is required',
                      regexFail: '',
                    },
                    validationButtonType: 'MCLP-submit',
                    rows: 0,
                    columns: 0,
                    onDemandError: false,
                    customValidation: [{ regularExpression: "/^\d+(\.\d+)?%?$/", errorMessage: 'please enter  valid data' }],
                    multiError: false,
                    applicationError: '',
                    wrapperClassName: '',
                    requiredErrorMessage: 'This fields is required',
                    showInputLabel: true,
                    labelContent: 'Cell  Viability/Confluency (%)',
                    labelClass: 'form-label',
                    isLabelAfter: false,
                    showAdditionalInputContent: false,
                    additionalInputContent: '',
                    isAdditonalInputAfter: true,
                    draggable: false,
                  },
                ],
                type: '',
                name: 'wrapper_625',
                classname: 'col-3 mb-3',
                wrapperType: 'BOX',
                id: 'wrapper_768',
                fields: [],
                head: '',
                tabHeadClass: '',
              },
             
              {
                control: [
                  {
                    type: 'text',
                    name: 'passage_no',
                    id: 'passage_no',
                    value: '',
                    placeholder: '',
                    multiline: false,
                    classname: 'form-control ',
                    regularExpression: '',
                    disabled: false,
                    required: true,
                    errorMessage: {
                      noData: 'This fields is required',
                      regexFail: '',
                    },
                    validationButtonType: 'MCLP-submit',
                    rows: 0,
                    columns: 0,
                    onDemandError: false,
                    customValidation: [
                      {
                        regularExpression: "/^(0\.[1-9]+|[1-9]\d*(\.\d+)?)$/",
                        errorMessage: 'Please type a valid number',
                      },
                    ],
                    multiError: false,
                    applicationError: '',
                    wrapperClassName: '',
                    requiredErrorMessage: 'This fields is required',
                    showInputLabel: true,
                    labelContent: 'Passage No',
                    labelClass: 'form-label',
                    isLabelAfter: false,
                    showAdditionalInputContent: false,
                    additionalInputContent: '',
                    isAdditonalInputAfter: true,
                    draggable: false,
                  },
                ],
                type: '',
                name: 'wrapper_668',
                classname: 'col-3 mb-3',
                wrapperType: 'BOX',
                id: 'wrapper_789',
                fields: [],
                head: '',
                tabHeadClass: '',
                draggable: false,
              },
              {
                control: [
                  {
                    type: 'dropdown',
                    name: 'qc_status',
                    id: 'qc_status ',
                    validationButtonType: 'MCLP-submit',
                    classname: '',
                    required: true,
                    apiEndPoints: [],
                    options: [
                      { id: 1, value: 'Passed' },
                      { id: 2, value: 'Failed' },
                    ],
                    multiple: false,
                    isClearable: false,
                    isStatus: false,
                    search: false,
                    singleSelect: true,
                    showLabel: 'value',
                    errorMessage: {
                      noData: 'This fields is required',
                    },
                    value: '',
                    imageUrl: '',
                    disabled: false,
                    wrapperClassName: '',
                    connectedArrays: [],
                    showBlankInPlaceholder: false,
                    blankDataList: [],
                    showInputLabel: true,
                    labelContent: 'QC Status ',
                    labelClass: 'form-label',
                    isLabelAfter: false,
                    showAdditionalInputContent: false,
                    additionalInputContent: '',
                    isAdditonalInputAfter: true,
                    draggable: false,
                  },
                ],
                type: '',
                name: 'wrapper_688',
                classname: 'col-3 mb-3',
                wrapperType: 'BOX',
                id: 'wrapper_41',
                fields: [],
                head: '',
                tabHeadClass: '',
                draggable: false,
              },
            ],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
        ],
        type: '',
        classname: '',
      },
      {
        control: [],
        type: '',
        name: 'wrapper_983',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_156',
        fields: [
          {
            control: [
              {
                type: 'label',
                name: 'Freezing & Aliquoting',
                classname: '',
                for: '',
                required: false,
                wrapperClassName: '',
                id: 'label_432',
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_95',
            classname: 'col-12  add-heading heading-without-icon ',
            wrapperType: 'BOX',
            id: 'wrapper_276',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
      },
      {
        control: [],
        head: '',
        name: 'main1',
        id: 'main1',
        wrapperType: 'BOX',
        fields: [
          {
            control: [],
            type: '',
            name: 'wrapper_647',
            classname: 'row',
            wrapperType: 'BOX',
            id: 'wrapper_556',
            fields: [
              {
                control: [
                  {
                    type: 'dropdown',
                    name: 'freezer_media',
                    id: 'freezer_media',
                    validationButtonType: 'MCLP-submit',
                    classname: '',
                    required: false,
                    apiEndPoints: [
                      {
                        type: 'init',
                        method: 'post',
                        url: 'inventory_item',
                        payload: {
                          user_input: '',
                          perma: 'cell-culture-media',
                        },
                      },
                    ],
                    options: [],
                    multiple: false,
                    isClearable: false,
                    isStatus: false,
                    search: false,
                    singleSelect: true,
                    showLabel: 'value',
                    errorMessage: {
                      noData: 'This fields is required',
                    },
                    value: '',
                    imageUrl: '',
                    disabled: false,
                    wrapperClassName: '',
                    connectedArrays: [],
                    showBlankInPlaceholder: false,
                    blankDataList: [],
                    showInputLabel: true,
                    labelContent: 'Freezing/Storage Media',
                    labelClass: 'form-label',
                    isLabelAfter: false,
                    showAdditionalInputContent: false,
                    additionalInputContent: '',
                    isAdditonalInputAfter: true,
                    draggable: false,
                  },
                ],
                type: '',
                name: 'wrapper_223',
                classname: 'col-3 mb-3',
                wrapperType: 'BOX',
                id: 'wrapper_601',
                fields: [],
                head: '',
                tabHeadClass: '',
              },
              {
                control: [
                  {
                    type: 'text',
                    name: 'cellCount',
                    id: 'cellCount',
                    value: '',
                    placeholder: '',
                    multiline: false,
                    classname: 'form-control ',
                    regularExpression: '',
                    disabled: false,
                    required: true,
                    errorMessage: {
                      noData: 'This fields is required',
                      regexFail: '',
                    },
                    validationButtonType: 'MCLP-submit',
                    rows: 0,
                    columns: 0,
                    onDemandError: false,
                    customValidation: [{ regularExpression: "/[.\d*^]+/", errorMessage: 'please enter  valid data' }],
                    multiError: false,
                    applicationError: '',
                    wrapperClassName: '',
                    requiredErrorMessage: 'This fields is required',
                    showInputLabel: true,
                    labelContent: 'Cell Count  <span>(millions/mL)</span>',
                    labelClass: 'form-label',
                    isLabelAfter: false,
                    showAdditionalInputContent: false,
                    additionalInputContent: '',
                    isAdditonalInputAfter: true,
                    draggable: false,
                  },
                ],
                type: '',
                name: 'wrapper_9',
                classname: 'col-3 mb-3',
                wrapperType: 'BOX',
                id: 'wrapper_298',
                fields: [],
                head: '',
                tabHeadClass: '',
              },
              {
                control: [
                  {
                    type: 'text',
                    name: 'cell_viabiality',
                    id: 'cell_viabiality',
                    value: '',
                    placeholder: '',
                    multiline: false,
                    classname: 'form-control ',
                    regularExpression: '',
                    disabled: false,
                    required: true,
                    errorMessage: {
                      noData: 'This fields is required',
                      regexFail: '',
                    },
                    validationButtonType: 'MCLP-submit',
                    rows: 0,
                    columns: 0,
                    onDemandError: false,
                    customValidation: [{ regularExpression: "/^\d+(\.\d+)?%?$/", errorMessage: 'please enter  valid data' }],
                    multiError: false,
                    applicationError: '',
                    wrapperClassName: '',
                    requiredErrorMessage: 'This fields is required',
                    showInputLabel: true,
                    labelContent: 'Cell  Viability/Confluency (%)',
                    labelClass: 'form-label',
                    isLabelAfter: false,
                    showAdditionalInputContent: false,
                    additionalInputContent: '',
                    isAdditonalInputAfter: true,
                    draggable: false,
                  },
                ],
                type: '',
                name: 'wrapper_625',
                classname: 'col-3 mb-3',
                wrapperType: 'BOX',
                id: 'wrapper_768',
                fields: [],
                head: '',
                tabHeadClass: '',
              },
              {
                control: [
                  {
                    type: 'text',
                    name: 'medium_vol',
                    id: 'medium_vol',
                    value: '',
                    placeholder: '',
                    multiline: false,
                    classname: 'form-control ',
                    regularExpression: '',
                    disabled: false,
                    required: true,
                    errorMessage: {
                      noData: 'This fields is required',
                      regexFail: '',
                    },
                    validationButtonType: 'MCLP-submit',
                    rows: 0,
                    columns: 0,
                    onDemandError: false,
                    customValidation: [
                      {
                        regularExpression: "/^(0\.[1-9]+|[1-9]\d*(\.\d+)?)$/",
                        errorMessage: 'Please type a valid number',
                      },
                    ],
                    multiError: false,
                    applicationError: '',
                    wrapperClassName: '',
                    requiredErrorMessage: 'This fields is required',
                    showInputLabel: true,
                    labelContent: 'Medium  Volume  <span>(mL)</span>',
                    labelClass: 'form-label',
                    isLabelAfter: false,
                    showAdditionalInputContent: false,
                    additionalInputContent: '',
                    isAdditonalInputAfter: true,
                    draggable: false,
                  },
                ],
                type: '',
                name: 'wrapper_96',
                classname: 'col-3 mb-3',
                wrapperType: 'BOX',
                id: 'wrapper_558',
                fields: [],
                head: '',
                tabHeadClass: '',
                draggable: false,
              },
              {
                control: [
                  {
                    type: 'dropdown',
                    name: 'TubeType',
                    id: 'TubeType ',
                    validationButtonType: 'MCLP-submit',
                    classname: '',
                    required: true,
                    apiEndPoints: [],
                    options: [
                      { id: 1, value: 'Cryovials' },
                      { id: 2, value: 'Cryo Tubes' },
                    ],
                    multiple: false,
                    isClearable: false,
                    isStatus: false,
                    search: false,
                    singleSelect: true,
                    showLabel: 'value',
                    errorMessage: {
                      noData: 'This fields is required',
                    },
                    value: '',
                    imageUrl: '',
                    disabled: false,
                    wrapperClassName: '',
                    connectedArrays: [],
                    showBlankInPlaceholder: false,
                    blankDataList: [],
                    showInputLabel: true,
                    labelContent: 'Tubetype',
                    labelClass: 'form-label',
                    isLabelAfter: false,
                    showAdditionalInputContent: false,
                    additionalInputContent: '',
                    isAdditonalInputAfter: true,
                    draggable: false,
                  },
                ],
                type: '',
                name: 'wrapper_267',
                classname: 'col-3 mb-3',
                wrapperType: 'BOX',
                id: 'wrapper_187',
                fields: [],
                head: '',
                tabHeadClass: '',
                draggable: false,
              },
            ],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [],
            type: '',
            name: 'wrapper_739',
            classname: 'row',
            wrapperType: 'BOX',
            id: 'wrapper_348',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
        ],
        type: '',
        classname: '',
      },
      {
        control: [],
        type: '',
        name: 'wrapper_133',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_329',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'comments',
                id: 'comments',
                value: '',
                placeholder: '',
                multiline: true,
                classname: 'form-control textarea',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: {
                  noData: 'This field is required',
                  regexFail: '',
                },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Experimental Notes/Comments',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_588',
            classname: 'col-12',
            wrapperType: 'BOX',
            id: 'wrapper_345',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },]