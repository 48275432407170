'use strict';

import { environment } from '../../../environments/environment';

export const ApiConstant = Object.freeze({
  BASE_URL: environment.apiEndPoint,
  AUTHENTICATION: {
    LOGIN: 'auth/login',
    GET_REFRESH_TOKEN: 'auth/refresh_token',
    LOGOUT: 'auth/logout',
    MENU: 'menu-list',
    CHECK_ACTIVATION_LINK: 'user_management/check_user_active_status',
    ACTIVATE_ACCOUNT: 'auth/user/activate_user',
    FORGOT_PASSWORD: 'auth/user/forgot_user_password',
    RESET_PASSWORD: 'auth/user/reset-user-password',
    VALIDATE_RESET_PASSWORD: 'user_management/validate_reset_password',
  },
  INVENTORY: {
    MASTER_DATA: {
      ENDPOINT: 'masterdata',
      PARAMETERS: {
        BACT_REST: 'bact_rest',
        BIO_LEVEL: 'bio_level',
        COLLAB: 'collab',
        INVENT: 'invent',
        INVENT_SOURCE: 'invent_source',
        MAM_REST: 'mam_rest',
        SAMP_FORM: 'samp_form',
        ST_TEMP: 'st_temp',
        BOX_TYPE: 'box_type',
        VENDOR: 'vendor',
      },
    },
    HISTORY_DATA: {
      ENDPOINT: 'history-log/get-history-list/',
      LOG: 'history-log/get-history-details/',
    },
    INVENTORY_TYPES_LIST: {
      ENDPOINT: 'masterdata/master-type-list',
      PARAMETERS: {
        REFRIGERATOR: 'refrigerator',
        PLASMIDS: 'plasmids',
        CELL_LINES: 'cell-lines',
        BACTERIAL_STARINS: 'bacterial-strains',
        FREEZER: 'freezer',
      },
    },
    BIOLOGICAL_SAMPLE_INVENTORY: {
      PLASMIDS: {
        ENDPOINT: 'master-plasmids',
        MASTER_LIST: 'master-plasmids/get_plasmid_master_list',
        DELETE_MASTER_PLASMID: 'master-plasmids/bulk-delete',
        INVENTORY: {
          ENDPOINT: 'inventory-plasmids',
          DELETE: 'inventory-plasmids/bulk-delete',
          STORAGE: 'box-details',
          INVENTORY_LIST: 'inventory-plasmids/get_plasmid_inventory_list',
          FILE_UPLOAD: 'inventory-plasmids/plasmids-file-upload',
          GET_FILE_DETAILS: 'inventory-plasmids/plasmids-file-view-download',
          DOWNLOAD_REPORT: 'inventory-plasmids/download',
        },
      },
      BACTERIAL_STRAINS: {
        ENDPOINT: 'bacterial-strains',
        GET_MASTER_LIST: 'get-bacterial-strain-master',
        DELETE_MASTER_STRAIN: 'bacterial-strains/delete_bulk_master_bacterialstrains',
        INVENTORY: {
          ENDPOINT: 'inventory-bacterialstrains',
          GET_INV_LIST: 'get-bacterialstrains-inventory',
          DELETE: 'inventory-bacterialstrains/bulk-delete',
          DOWNLOAD_REPORT: 'inventory-bacterialstrains/download',
        },
      },
      CELL_LINES: {
        ENDPOINT: 'cell-lines',
        MASTER: '/get-celllines-master',
        DELETE_MASTER_CELL: 'cell-lines/delete_bulk_master_celllines',
        INVENTORY: {
          ENDPOINT: 'inventory-celllines',
          GETINVENTORY: 'get-celllines-inventory',
          DELETE: 'inventory-celllines/bulk-delete',
          DOWNLOAD_REPORT: 'inventory-celllines/download',
        },
      },
    },
    LAB_EQUIPMENTS: {
      REFRIGERATOR: {
        ENDPOINT: 'refrigerator',
        DELETE_MASTER_REFRIGERATOR: 'refrigerator/bulk-delete',
        MASTER_LIST: 'refrigerator/get_refrigerator_master_list',
        DOWNLOAD_REPORT: 'inventory/download/refrigerator',
      },
      INVENTORY: {
        ENDPOINT: 'inventory',
        DELETE_INV: 'inventory/bulk-delete',
        INVENTORY_LIST: 'inventory/get_inventiry_items',
      },
      FREEZER: {
        ENDPOINT: 'freezer',
        DELETE_MASTER_FREEZER: 'freezer/delete_bulk_master_freezer',
        MASTER_LIST: 'freezer/get_freezer_master_list',
        DOWNLOAD_REPORT: 'inventory/download/freezer',
      },
      STORAGE_CABINETS_AND_SHELVES: {
        ENDPOINT: 'lab_equipments_router',
        MASTER_LIST: 'lab_equipments_router/get_lab_equipments_list',
        DELETE_MASTER: 'delete_lab_equipment',
        DOWNLOAD_REPORT_SC: 'inventory/download/storage-cabinet',
        DOWNLOAD_REPORT_SHELVES: 'inventory/download/shelves',
      },
    },
    ASSIGN_STORAGE: {
      PLASMID: {
        ENDPOINT: 'inventory-plasmids',
        STORAGE_TYPE: 'storage-type',
        BOX_DETAILS: 'box-details',
        GRID_DETAILS: 'grid-details',
        STORAGE_BOX: 'storage-box',
        GRIDINFO: 'grid-info',
        DISCARD: 'discard',
      },
    },
    LAB_CONSUMABLES: {
      MASTER: {
        ENDPOINT: 'labconsumables-master',
        MASTER_ENDPOINT: 'get-labconsumables-master-list',
        DELETE: 'labconsumables-master/delete',
      },
      INVENTORY: {
        END_POINT: 'inventory-lab-consumable',
        GET_LIST: 'inventory-lab-consumable/list',
      },
      PERMA: {
        ASSAYKITS: 'assay-kits',
        PLASTICWARE: 'plasticwares',
        GLASSWARE: 'glasswares',
        AGARPLATES: 'agar-plates',
        CELLCULTUREMEDIA: 'cell-culture-media',
        ANTIBIOTICS: 'antibiotics',
        GELS: 'gels',
        BUFFERS: 'buffers',
        LABREAGENTS: 'lab-reagents',
      },
      DOWNLOAD_REPORT: {
        ASSAYKITS: 'inventory-lab-consumable/download/assay-kits',
        PLASTICWARE: 'inventory-lab-consumable/download/plasticwares',
        GLASSWARE: 'inventory-lab-consumable/download/glasswares',
        AGARPLATES: 'inventory-lab-consumable/download/agar-plates',
        CELLCULTUREMEDIA: 'inventory-lab-consumable/download/cell-culture-media',
        ANTIBIOTICS: 'inventory-lab-consumable/download/antibiotics',
        GELS: 'inventory-lab-consumable/download/gels',
        BUFFERS: 'inventory-lab-consumable/download/buffers',
        LABREAGENTS: 'inventory-lab-consumable/download/lab-reagents',
      },
    },
    INSTRUMENTS: {
      ENDPOINT: 'Instruments-master',
      GET_MASTER_LIST: 'get-instruments-master-list',
      INVENTORY: {
        ENDPOINT: 'inventory-instruments',
        GET_LIST: 'inventory-instruments/get-instruments-inventory',
        DOWNLOAD_REPORT: 'inventory-instruments/download',
      },
    },
    INVENTORY_SUB_TYPES_LIST: {
      ENDPOINT: 'inventory-plasmids/inventory-sub-types',
      PARAMETERS: {},
    },
    PROTOCOLS: {
      ENDPOINT: 'protocol',
      GET_MASTER_LIST: 'list',
      APPROVE_REJECT: 'protocol/approve-reject-protocol',
    },
    FILEUPLOAD: {
      ENDPOINT: 'file_upload',
      GET_FILE_DETAILS: 'file_upload/file-view-download',
      DELETE_FILE: 'file_upload/file-upload-delete',
    },
  },
  PROJECTS: {
    ENDPOINT: 'projects',
    SAVE_PROJECT_INITIATION_DETAILS: 'project_initiation',
    SAVE_PROJECT_PLASMID_INFO: 'project_plasmid_information',
    SAVE_PROJECT_PLASMID_INFO_Plasmid_Extraction_Purification: 'project_bacterial_stock_information',
    SAVE_PROJECT_ANTIBODY_INFO: 'project_antibody_information',
    SAVE_PROJECT_MAMMALIAN_INFO: 'project_cellline_information',
    MASTER_DATA: {
      ENDPOINT: 'masterdata',
    },
    GET_VIALS: 'bacterial-stock-prepared-vials',
    GET_LIST: 'projects/list',
    UPDATE_STATUS: 'projects-workflow/project-status',
    DOWNLOAD_PDF: 'report/pdf',
  },
  WORK_FLOW: {
    SAVE_PREPARATION: 'projects-workflow',
    SAVE_BACTERIAL_STOCK_CREATION: 'projects-workflow/bacterial-stock-preparation',
    SAVE_EXTRACTION: 'projects-workflow/extraction',
    SAVE_AB_PRODUCTION: 'projects-workflow/save-antibody-production',
    SAVE_AB_P_FPLC: 'projects-workflow/save-antibody-purification-fplc',
    SAVE_AB_PURIFICATION: 'projects-workflow/save-antibody-purification-protein',
    SAVE_AB_QC: 'projects-workflow/save-antibody-qc',
    SAVE_M_CL_STOCK: 'projects-workflow/save-mammalian-cell-line-stock-prep',
    SAVE_MC_STORAGE: 'projects-workflow/save-mammalian-cell-line-storage',
  },
  PROTOCOLS: {
    ENDPOINT: 'protocol',
    GET_MASTER_LIST: 'list',
    GET_PROTOCOL_COMMENTS: 'protocol/protocol-comment-list',
    DOWNLOAD: 'protocol/download',
  },
  DYNAMIC_FORMS:{
    GET_JSON:'dynamic-form'
  },
  VENDORS: {
    ENDPOINT: 'vendor',
    GET_MASTER_LIST: 'list',
    DEL_MASTER_LIST_VENDOR: 'vendor',
    GET_JSON:'dynamic-form'
    // GET_PROTOCOL_COMMENTS: 'protocol/protocol-comment-list',
  },
  // PRODUCTS
  PRODUCTS: {
    ENDPOINT: 'product_inventory',
  },
  SETTINGS: {
    USER: {
      ADDUSER: 'auth/user',
      ENDPOINT: 'user_management',
      USERLIST: 'user_management/users_detail_list',
      CHNAGEPASSWORD: 'auth/change_password',
      DELETEUSER:'auth/user'
    },
  },
  JSONEDITOR:{
    UPDATEJSON:'dynamic-form'
  }
});
