
export const ProductDynamicJSON = [
  //product glycerol stock filter
  {
    "jsonId": 87,
    "control": [],
    "head": "",
    "name": "main",
    "id": "main",
    "wrapperType": "REGULAR",
    "fields": [
      {
        "control": [],
        "type": "",
        "name": "wrapper_275",
        "classname": "row",
        "wrapperType": "BOX",
        "id": "wrapper_548",
        "fields": [
          {
            "control": [
              {
                "type": "text",
                "name": "project_id",
                "id": "project_id",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": false,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Project Id",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_234",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_521",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "project_title",
                "id": "project_title",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": false,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Project Title",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_541",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_205",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          },
          {
            "control": [
              {
               type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                "name": "project_investigator",
                "id": "project_investigator",
                "validationButtonType": "",
                "classname": "",
                "required": false,
                "apiEndPoints": [
                  {
                    "type": "init",
                    "method": "get",
                    "url": "user_management/users_list"
                  }
                ],
                "options": [],
                "multiple": false,
                "isClearable": false,
                "isStatus": false,
                "search": false,
                "singleSelect": true,
                "showLabel": "value",
                "errorMessage": {
                  "noData": "This field is required"
                },
                "value": "",
                "imageUrl": "",
                "disabled": false,
                "wrapperClassName": "",
                "connectedArrays": [],
                "showInputLabel": true,
                "labelContent": "Project Investigator",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_426",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_569",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          },
          {
            "control": [
              {
                "type": "datepicker_v2",
                "name": "expiry_date",
                "id": "expiry_date",
                "validationButtonType": "",
                "range": true,
                "value": "",
                "multiple": false,
                "multiAmount": 3,
                "keyboardInput": false,
                "startDateReadOnly": false,
                "endDateReadOnly": false,
                "fordisabledStart": "",
                "fordisabledEnd": "",
                "timer": false,
                "hourFormat": 24,
                "placeholder": "DD-MM-YYYY",
                "startDatePlaceHolder": "MM/DD/YYYY",
                "inputClassName": "form-control date-input",
                "selectedDateClassName": "selectedDate",
                "arrowClassName": "arrow",
                "dayClassName": "dd",
                "todayClassName": "now",
                "monthClassName": "mtn",
                "regularExpression": "/^(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4}s+tos+(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4}$/",
                "required": false,
                "classname": "",
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": "Date is not valid!!"
                },
                "dateFormat": "dd/mm/yyyy",
                "disabled": false,
                "wrapperClassName": "",
                "locale": "",
                "dateSeperator": "",
                "inputDateFormat": false,
                "isHostParent": false,
                "showInputLabel": true,
                "labelContent": "Expiry Date",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_512",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_935",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "plasmid",
                "id": "plasmid",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": false,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Plasmid",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_234",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_521",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "stock_id",
                "id": "stock_id",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": false,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Stock Id",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_234",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_521",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
               type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                "name": "status",
                "id": "status",
                "validationButtonType": "",
                "classname": "",
                "required": false,
                "apiEndPoints": [],
                "options": [
                  {
                    "id": "Available",
                    "value": "Available"
                  },
                  {
                    "id": "Discarded",
                    "value": "Discarded"
                  }
                ],
                "multiple": false,
                "isClearable": false,
                "isStatus": false,
                "search": false,
                "singleSelect": true,
                "showLabel": "value",
                "errorMessage": {
                  "noData": "This field is required"
                },
                "value": "",
                "imageUrl": "",
                "disabled": false,
                "wrapperClassName": "",
                "connectedArrays": [],
                "showInputLabel": true,
                "labelContent": "Project Status",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_426",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_569",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      }
    ],
    "type": "",
    "classname": ""
  },
  //extracted plasmids stock filter
  {
    "jsonId": 88,
    "control": [],
    "head": "",
    "name": "main",
    "id": "main",
    "wrapperType": "REGULAR",
    "fields": [
      {
        "control": [],
        "type": "",
        "name": "wrapper_2775",
        "classname": "row",
        "wrapperType": "BOX",
        "id": "wrapper_5498",
        "fields": [
          {
            "control": [
              {
                "type": "text",
                "name": "project_id",
                "id": "project_id",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": false,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Project Id",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_234",
            "classname": "col mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_521",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "project_title",
                "id": "project_title",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": false,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Project Title",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_541",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_205",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          },
          {
            "control": [
              {
               type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                "name": "project_investigator",
                "id": "project_investigator",
                "validationButtonType": "",
                "classname": "",
                "required": false,
                "apiEndPoints": [
                  {
                    "type": "init",
                    "method": "get",
                    "url": "user_management/users_list"
                  }
                ],
                "options": [],
                "multiple": false,
                "isClearable": false,
                "isStatus": false,
                "search": false,
                "singleSelect": true,
                "showLabel": "value",
                "errorMessage": {
                  "noData": "This field is required"
                },
                "value": "",
                "imageUrl": "",
                "disabled": false,
                "wrapperClassName": "",
                "connectedArrays": [],
                "showInputLabel": true,
                "labelContent": "Project Investigator",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_426",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_569",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          },
          {
            "control": [
              {
                "type": "datepicker_v2",
                "name": "expiry_date",
                "id": "expiry_date",
                "validationButtonType": "",
                "range": true,
                "value": "",
                "multiple": false,
                "multiAmount": 3,
                "keyboardInput": false,
                "startDateReadOnly": false,
                "endDateReadOnly": false,
                "fordisabledStart": "",
                "fordisabledEnd": "",
                "timer": false,
                "hourFormat": 24,
                "placeholder": "DD-MM-YYYY",
                "startDatePlaceHolder": "MM/DD/YYYY",
                "inputClassName": "form-control date-input",
                "selectedDateClassName": "selectedDate",
                "arrowClassName": "arrow",
                "dayClassName": "dd",
                "todayClassName": "now",
                "monthClassName": "mtn",
                "regularExpression": "/^(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4}s+tos+(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4}$/",
                "required": false,
                "classname": "",
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": "Date is not valid!!"
                },
                "dateFormat": "dd/mm/yyyy",
                "disabled": false,
                "wrapperClassName": "",
                "locale": "",
                "dateSeperator": "",
                "inputDateFormat": false,
                "isHostParent": false,
                "showInputLabel": true,
                "labelContent": "Expiry Date",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_512",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_935",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "plasmid",
                "id": "plasmid",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": false,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Plasmid",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_234",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_521",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "stock_id",
                "id": "stock_id",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": false,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Stock Id",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_234",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_521",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
               type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                "name": "status",
                "id": "status",
                "validationButtonType": "",
                "classname": "",
                "required": false,
                "apiEndPoints": [],
                "options": [
                  {
                    "id": "Available",
                    "value": "Available"
                  },
                  {
                    "id": "Discarded",
                    "value": "Discarded"
                  }
                ],
                "multiple": false,
                "isClearable": false,
                "isStatus": false,
                "search": false,
                "singleSelect": true,
                "showLabel": "value",
                "errorMessage": {
                  "noData": "This field is required"
                },
                "value": "",
                "imageUrl": "",
                "disabled": false,
                "wrapperClassName": "",
                "connectedArrays": [],
                "showInputLabel": true,
                "labelContent": "Project Status",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_426",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_569",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      }
    ],
    "type": "",
    "classname": ""
  },
  //product cell lines filter
  {
    "jsonId": 89,
    "control": [],
    "head": "",
    "name": "main",
    "id": "main",
    "wrapperType": "REGULAR",
    "fields": [
      {
        "control": [],
        "type": "",
        "name": "wrapper_2775",
        "classname": "row",
        "wrapperType": "BOX",
        "id": "wrapper_5498",
        "fields": [
          {
            "control": [
              {
                "type": "text",
                "name": "project_id",
                "id": "project_id",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": false,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Project Id",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_234",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_521",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "project_title",
                "id": "project_title",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": false,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Project Title",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_541",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_205",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          },
          {
            "control": [
              {
               type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                "name": "project_investigator",
                "id": "project_investigator",
                "validationButtonType": "",
                "classname": "",
                "required": false,
                "apiEndPoints": [
                  {
                    "type": "init",
                    "method": "get",
                    "url": "user_management/users_list"
                  }
                ],
                "options": [],
                "multiple": false,
                "isClearable": false,
                "isStatus": false,
                "search": false,
                "singleSelect": true,
                "showLabel": "value",
                "errorMessage": {
                  "noData": "This field is required"
                },
                "value": "",
                "imageUrl": "",
                "disabled": false,
                "wrapperClassName": "",
                "connectedArrays": [],
                "showInputLabel": true,
                "labelContent": "Project Investigator",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_426",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_569",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          },
          {
            "control": [
              {
                "type": "datepicker_v2",
                "name": "expiry_date",
                "id": "expiry_date",
                "validationButtonType": "",
                "range": true,
                "value": "",
                "multiple": false,
                "multiAmount": 3,
                "keyboardInput": false,
                "startDateReadOnly": false,
                "endDateReadOnly": false,
                "fordisabledStart": "",
                "fordisabledEnd": "",
                "timer": false,
                "hourFormat": 24,
                "placeholder": "DD-MM-YYYY",
                "startDatePlaceHolder": "MM/DD/YYYY",
                "inputClassName": "form-control date-input",
                "selectedDateClassName": "selectedDate",
                "arrowClassName": "arrow",
                "dayClassName": "dd",
                "todayClassName": "now",
                "monthClassName": "mtn",
                "regularExpression": "/^(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4}s+tos+(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4}$/",
                "required": false,
                "classname": "",
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": "Date is not valid!!"
                },
                "dateFormat": "dd/mm/yyyy",
                "disabled": false,
                "wrapperClassName": "",
                "locale": "",
                "dateSeperator": "",
                "inputDateFormat": false,
                "isHostParent": false,
                "showInputLabel": true,
                "labelContent": "Expiry Date",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_512",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_935",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "plasmid",
                "id": "plasmid",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": false,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Plasmid",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_234",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_521",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "cell_line",
                "id": "cell_line",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": false,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Cell Line",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_234",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_521",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "cell_count",
                "id": "cell_count",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": false,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Cell Count  <span>(millions/mL)</span>",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_234",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_521",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
               type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                "name": "status",
                "id": "status",
                "validationButtonType": "",
                "classname": "",
                "required": false,
                "apiEndPoints": [],
                "options": [
                  {
                    "id": "Available",
                    "value": "Available"
                  },
                  {
                    "id": "Discarded",
                    "value": "Discarded"
                  }
                ],
                "multiple": false,
                "isClearable": false,
                "isStatus": false,
                "search": false,
                "singleSelect": true,
                "showLabel": "value",
                "errorMessage": {
                  "noData": "This field is required"
                },
                "value": "",
                "imageUrl": "",
                "disabled": false,
                "wrapperClassName": "",
                "connectedArrays": [],
                "showInputLabel": true,
                "labelContent": "Project Status",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_426",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_569",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      }
    ],
    "type": "",
    "classname": ""
  },
  //antibody product lines filter
  {
    "jsonId": 90,
    "control": [],
    "head": "",
    "name": "main",
    "id": "main",
    "wrapperType": "REGULAR",
    "fields": [
      {
        "control": [],
        "type": "",
        "name": "wrapper_2775",
        "classname": "row",
        "wrapperType": "BOX",
        "id": "wrapper_5498",
        "fields": [
          {
            "control": [
              {
                "type": "text",
                "name": "project_id",
                "id": "project_id",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": false,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Project Id",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_234",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_521",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "project_title",
                "id": "project_title",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": false,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Project Title",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_541",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_205",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          },
          {
            "control": [
              {
               type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                "name": "project_investigator",
                "id": "project_investigator",
                "validationButtonType": "",
                "classname": "",
                "required": false,
                "apiEndPoints": [
                  {
                    "type": "init",
                    "method": "get",
                    "url": "user_management/users_list"
                  }
                ],
                "options": [],
                "multiple": false,
                "isClearable": false,
                "isStatus": false,
                "search": false,
                "singleSelect": true,
                "showLabel": "value",
                "errorMessage": {
                  "noData": "This field is required"
                },
                "value": "",
                "imageUrl": "",
                "disabled": false,
                "wrapperClassName": "",
                "connectedArrays": [],
                "showInputLabel": true,
                "labelContent": "Project Investigator",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_426",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_569",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          },
          {
            "control": [
              {
                "type": "datepicker_v2",
                "name": "expiry_date",
                "id": "expiry_date",
                "validationButtonType": "",
                "range": true,
                "value": "",
                "multiple": false,
                "multiAmount": 3,
                "keyboardInput": false,
                "startDateReadOnly": false,
                "endDateReadOnly": false,
                "fordisabledStart": "",
                "fordisabledEnd": "",
                "timer": false,
                "hourFormat": 24,
                "placeholder": "DD-MM-YYYY",
                "startDatePlaceHolder": "MM/DD/YYYY",
                "inputClassName": "form-control date-input",
                "selectedDateClassName": "selectedDate",
                "arrowClassName": "arrow",
                "dayClassName": "dd",
                "todayClassName": "now",
                "monthClassName": "mtn",
                "regularExpression": "/^(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4}s+tos+(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4}$/",
                "required": false,
                "classname": "",
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": "Date is not valid!!"
                },
                "dateFormat": "dd/mm/yyyy",
                "disabled": false,
                "wrapperClassName": "",
                "locale": "",
                "dateSeperator": "",
                "inputDateFormat": false,
                "isHostParent": false,
                "showInputLabel": true,
                "labelContent": "Expiry Date",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_512",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_935",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "plasmid",
                "id": "plasmid",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": false,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Plasmid",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_234",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_521",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
                "type": "text",
                "name": "Antibody",
                "id": "Antibody",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": false,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Antibody",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_234",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_521",
            "fields": [],
            "head": "",
            "tabHeadClass": "",
            "draggable": false
          },
          {
            "control": [
              {
               type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                "name": "status",
                "id": "status",
                "validationButtonType": "",
                "classname": "",
                "required": false,
                "apiEndPoints": [],
                "options": [
                  {
                    "id": "Available",
                    "value": "Available"
                  },
                  {
                    "id": "Discarded",
                    "value": "Discarded"
                  }
                ],
                "multiple": false,
                "isClearable": false,
                "isStatus": false,
                "search": false,
                "singleSelect": true,
                "showLabel": "value",
                "errorMessage": {
                  "noData": "This field is required"
                },
                "value": "",
                "imageUrl": "",
                "disabled": false,
                "wrapperClassName": "",
                "connectedArrays": [],
                "showInputLabel": true,
                "labelContent": "Project Status",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_426",
            "classname": "col-6 mb-3",
            "wrapperType": "BOX",
            "id": "wrapper_569",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      }
    ],
    "type": "",
    "classname": ""
  }
]