import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, Subject, takeUntil, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiConstant } from '../constants/api-constants';
import { VendorDynamicJSON } from '../constants/dynamic-json/vendors-dynamic-json';
import { DynamicJSON } from '../constants/dynamic-json';
import { sharedJson } from'../constants/sharedAngularModules'
import { LoaderService } from './loader.service';
import { ToasterService } from './toaster.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
 
  unSubscribe = new Subject<void>();

  constructor(private http: HttpClient,
    // private apiService: ApiService,
    private loaderService: LoaderService,
    private toasterService: ToasterService,

    ) {}
  private jsonFileMap = sharedJson;
  
  baseUrl = environment.apiEndPoint;
  // constructor(private http: HttpClient,
  //   ) {}
  //   public getJSON(id: number) {
    
  //     return DynamicJSON.find((item) => item.jsonId === id);
  // }

  public getDynamicJSON(id: any, permaValue?: string) {
    console.log(id);
    
    if (permaValue) {
     
     return null
    } else {
        for (let el of this.jsonFileMap) {
            for (let item of el) {
                if (item.jsonId == id) {
                    return item;
                }
            }
        }
        return null; 
    }
}



// public getJsonFromApi(permaValue: any): Observable<any> {
//   const endpoint = this.getApiUrl(ApiConstant.VENDORS.GET_JSON + '/' + `${permaValue}`);
//   this.loaderService.displayLoader(true);

//   return this.ExecuteGet(endpoint).pipe(
//     takeUntil(this.unSubscribe),
//     tap({
//       next: (response: any) => {
//         this.loaderService.displayLoader(false);
//         if (!response?.success) {
//           this.toasterService.alert.toast({
//             type: 'error',
//             message: response.message,
//           });
//         }
//       },
//       error: () => {
//         this.loaderService.displayLoader(false);
//       }
//     }),
//     map((response: any) => response?.success ? response.data.records[0].dynamic_json : null)
//   );
// }

public getJsonFromApi(permaValue: any): Observable<any> {
  const endpoint = this.getApiUrl(ApiConstant.DYNAMIC_FORMS.GET_JSON + '/' + `${permaValue}`);
  this.loaderService.displayLoader(true);

  return this.ExecuteGet(endpoint).pipe(
    tap({
      next: (response: any) => {
        this.loaderService.displayLoader(false);
        if (!response?.success) {
          this.toasterService.alert.toast({
            type: 'error',
            message: response.message,
          });
        }
      },
      error: () => {
        this.loaderService.displayLoader(false);
      }
    }),
    map((response: any) => response?.success ? response.data.records[0].dynamic_json : null)
  );
}
 

  public ExecuteGet(url: string): Observable<Object> {
    return this.http.get(url);
  }

  public ExecutePost(url: string, body: any, formData?: boolean): Observable<Object> {
    return !formData ? this.http.post(url, JSON.parse(JSON.stringify(body))) : this.http.post(url, body);
  }

  public ExecutePut(url: string, body: any): Observable<Object> {
    return this.http.put(url, body);
  }

  public ExecuteDelete(url: string, body?: any): Observable<Object> {
    return body ? this.http.delete(url, { body: body }) : this.http.delete(url);
  }

  public ExecutePatch(url: string, body: any): Observable<Object> {
    return this.http.patch(url, body);
  }

  public getApiUrl = (
    relativeEndpoint: string,
    queryParams?: GeneralKeyValue | any | null,
    routeParams?: GeneralKeyValue | null,
    alternateApiDomain?: string,
  ): string => {
    let url = `${alternateApiDomain || environment.apiEndPoint}/${relativeEndpoint}`;
    if (queryParams) {
      let params = '';
      url += this.setIfQueryParams(queryParams, params);
    }
    if (routeParams) {
      for (let key in routeParams) {
        if (routeParams[key]) {
          url = url.replace(`{${key}}`, routeParams[key] as string);
        }
      }
    }
    return url;
  };

  setIfQueryParams = (queryParams: GeneralKeyValue | any | null, params: string) => {
    for (let key in queryParams) {
      if (queryParams[key] == null || queryParams[key] == undefined || queryParams[key] == '') continue;
      params += params ? '&' : '?';
      if (Array.isArray(queryParams[key])) {
        queryParams[key].forEach((v: any, i: number) => {
          if (params && !params.endsWith('&') && !params.endsWith('?')) {
            params += '&';
          }
          params += `${key}=${v}`;
        });
      } else {
        if (typeof queryParams[key] == 'object') {
          params += `${key}=${JSON.stringify(queryParams[key])}`;
        } else {
          params += `${key}=${queryParams[key]}`;
        }
      }
    }
    return params;
  };

  public isTokenIgnore = (url: string) => {
    const tokenIgnoreList = [
      ApiConstant.AUTHENTICATION.LOGIN,
      ApiConstant.AUTHENTICATION.CHECK_ACTIVATION_LINK,
      ApiConstant.AUTHENTICATION.ACTIVATE_ACCOUNT,
      ApiConstant.AUTHENTICATION.FORGOT_PASSWORD,
      ApiConstant.AUTHENTICATION.VALIDATE_RESET_PASSWORD,
      ApiConstant.AUTHENTICATION.RESET_PASSWORD,
    ];
    const absUrl = url.split('?')[0];
    const found = tokenIgnoreList.find((relativeUrl) => absUrl?.includes(relativeUrl));
    return found ? true : false;
  };
}
export type GeneralKeyValue = {
  [key: string]: string | number | boolean | null;
};
