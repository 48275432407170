export const UsermgtDynamicJSON =[//Add New user
{
  jsonId: 97,
  control: [],
  head: '',
  name: 'main',
  id: 'main',
  wrapperType: 'REGULAR',
  fields: [
    {
      control: [],
      type: '',
      name: 'wrapper_963',
      classname: '',
      wrapperType: 'BOX',
      id: 'wrapper_517',
      fields: [
        {
          control: [
            {
              type: 'text',
              name: 'first_name',
              id: 'first_name',
              value: '',
              placeholder: '',
              multiline: false,
              classname: 'form-control ',
              regularExpression: '',
              disabled: false,
              required: true,
              errorMessage: { noData: 'This field is required', regexFail: '' },
              validationButtonType: 'add-user',
              rows: 0,
              columns: 0,
              onDemandError: false,
              customValidation: [],
              multiError: false,
              applicationError: '',
              wrapperClassName: '',
              requiredErrorMessage: 'This field is required',
              showInputLabel: true,
              labelContent: 'First Name',
              labelClass: 'form-label mandatory',
              isLabelAfter: false,
              showAdditionalInputContent: false,
              additionalInputContent: '',
              isAdditonalInputAfter: true,
              draggable: false,
            },
          ],
          type: '',
          name: 'wrapper_39',
          classname: 'form-group',
          wrapperType: 'BOX',
          id: 'wrapper_72',
          fields: [],
          head: '',
          tabHeadClass: '',
        },
        {
          control: [
            {
              type: 'text',
              name: 'last_name',
              id: 'last_name',
              value: '',
              placeholder: '',
              multiline: false,
              classname: 'form-control ',
              regularExpression: '',
              disabled: false,
              required: false,
              errorMessage: { noData: 'This field is required', regexFail: '' },
              validationButtonType: 'add-user',
              rows: 0,
              columns: 0,
              onDemandError: false,
              customValidation: [],
              multiError: false,
              applicationError: '',
              wrapperClassName: '',
              requiredErrorMessage: 'This field is required',
              showInputLabel: true,
              labelContent: 'Last Name',
              labelClass: 'form-label',
              isLabelAfter: false,
              showAdditionalInputContent: false,
              additionalInputContent: '',
              isAdditonalInputAfter: true,
              draggable: false,
            },
            {
              type: 'radio',
              name: 'is_edit',
              id: 'is_edit',
              value: 0,
              required: false,
              classname: 'form-check-wrap d-none',
              errorMessage: { noData: '' },
              options: [
                { key: 1, label: 'True' },
                { key: 0, label: 'False' },
              ],
              functionName: '',
              readonly: false,
              validationButtonType: 'submit',
              disabled: false,
              wrapperClassName: 'form-check form-check-inline',
              showInputLabel: true,
              labelContent: 'Expiry Date',
              labelClass: 'form-label',
              isLabelAfter: false,
              showAdditionalInputContent: false,
              additionalInputContent: '',
              isAdditonalInputAfter: true,
              draggable: false,
              controlPoints: [
                {
                  type: 'change',
                  value: 1,
                  Mapto: [
                    {
                      id: 'main.wrapper_517.wrapper_7014', 
                      type: 'showField',
                    },
                    // {
                    //   id: 'main.wrapper_517.wrapper_7015', //password
                    //   type: 'hideField',
                    // },
                    {
                      id: 'main.wrapper_517.wrapper_7344', 
                      type: 'showField',
                    },
                  ],
                },
                {
                  type: 'change',
                  value: 0,
                  Mapto: [
                    {
                      id: 'main.wrapper_517.wrapper_7014', 
                      type: 'showField',
                    },
                    // {
                    //   id: 'main.wrapper_517.wrapper_7015', //password
                    //   type: 'showField',
                    // },
                    {
                      id: 'main.wrapper_517.wrapper_7344',
                      type: 'hideField',
                    },
                  ],
                },
              ],
            },
          ],
          type: '',
          name: 'wrapper_421',
          classname: 'form-group',
          wrapperType: 'BOX',
          id: 'wrapper_809',
          fields: [],
          head: '',
          tabHeadClass: '',
        },
        {
          control: [
            {
              type: 'dropdown',
              name: 'role',
              id: 'role ',
              validationButtonType: 'add-user',
              classname: '',
              required: true,
              apiEndPoints: [
                {
                  type: 'init',
                  method: 'post',
                  url: 'masterdata',
                  payload: {
                    data_types: ['role'],
                  },
                },
              ],
              options: [],
              multiple: false,
              isClearable: false,
              isStatus: false,
              search: false,
              singleSelect: true,
              showLabel: 'value',
              errorMessage: {
                noData: 'This field is required',
              },
              value: '',
              imageUrl: '',
              disabled: false,
              wrapperClassName: '',
              connectedArrays: [],
              showBlankInPlaceholder: false,
              blankDataList: [],
              showInputLabel: true,
              labelContent: 'User Role',
              labelClass: 'form-label mandatory',
              isLabelAfter: false,
              showAdditionalInputContent: false,
              additionalInputContent: '',
              isAdditonalInputAfter: true,
              draggable: false,
            },
          ],
          type: '',
          name: 'wrapper_267',
          classname: 'col-12 mb-3',
          wrapperType: 'BOX',
          id: 'wrapper_187',
          fields: [],
          head: '',
          tabHeadClass: '',
          draggable: false,
        },
        {
          control: [
            {
              type: 'text',
              name: 'email',
              id: 'email',
              value: '',
              placeholder: '',
              multiline: false,
              classname: 'form-control ',
              regularExpression: '',
              disabled: false,
              required: true,
              errorMessage: { noData: 'This field is required', regexFail: '' },
              validationButtonType: 'add-user',
              rows: 0,
              columns: 0,
              onDemandError: true,
              customValidation: [
                {
                  regularExpression:
                    /^(([^<>()\[\]\\.,.;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  errorMessage: 'Please enter a valid email',
                },
              ],
              multiError: false,
              applicationError: '',
              wrapperClassName: '',
              requiredErrorMessage: 'This field is required',
              showInputLabel: true,
              labelContent: 'Email Address',
              labelClass: 'form-label mandatory',
              isLabelAfter: false,
              showAdditionalInputContent: false,
              additionalInputContent: '',
              isAdditonalInputAfter: true,
              draggable: false,
            },
          ],
          type: '',
          name: 'wrapper_959',
          classname: 'form-group',
          wrapperType: 'BOX',
          id: 'wrapper_7014',
          fields: [],
          head: '',
          tabHeadClass: '',
          draggable: false,
        },
        // {
        //   control: [
        //     {
        //       type: 'password',
        //       name: 'password',
        //       id: 'password',
        //       value: '',
        //       placeholder: '',
        //       multiline: false,
        //       classname: 'form-control ',
        //       regularExpression: '',
        //       disabled: false,
        //       required: true,
        //       errorMessage: { noData: 'This field is required', regexFail: '' },
        //       validationButtonType: 'add-user',
        //       rows: 0,
        //       columns: 0,
        //       onDemandError: true,
        //       customValidation: [
        //         {
        //           regularExpression: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}:<>?]).{8,}$/,
        //           errorMessage: 'Please enter a valid password !',
        //         },
        //       ],
        //       multiError: false,
        //       applicationError: '',
        //       wrapperClassName: '',
        //       requiredErrorMessage: 'This field is required',
        //       showInputLabel: true,
        //       labelContent:
        //         'Password<span class="mandatory-info">*</span> <div class="info info-password"><div class="custom-tooltip"><p>Password should contain at least 8 characters<br>At least one number<br>At least one alphabets<br>At least one special character </p></div></div>',
        //       labelClass: 'form-label',
        //       isLabelAfter: false,
        //       showAdditionalInputContent: false,
        //       additionalInputContent: '',
        //       isAdditonalInputAfter: true,
        //       draggable: false,
        //     },
        //   ],
        //   type: '',
        //   name: 'wrapper_9594',
        //   classname: 'form-group',
        //   wrapperType: 'BOX',
        //   id: 'wrapper_7015',
        //   fields: [],
        //   head: '',
        //   tabHeadClass: '',
        //   draggable: false,
        // },
        {
          control: [
            {
              type: 'dropdown',
              name: 'status',
              id: 'status',
              validationButtonType: 'add-user',
              classname: '',
              required: true,
              apiEndPoints: [],
              options: [
                { id: 1, value: 'Active' },
                { id: 2, value: 'Disabled' },
              ],
              multiple: false,
              isClearable: false,
              isStatus: false,
              search: false,
              singleSelect: true,
              showLabel: 'value',
              errorMessage: { noData: 'This field is required' },
              value: '',
              imageUrl: '',
              disabled: false,
              wrapperClassName: '',
              connectedArrays: [],
              showBlankInPlaceholder: false,
              blankDataList: [],
              showInputLabel: true,
              labelContent: 'Status ',
              labelClass: 'form-label mandatory',
              isLabelAfter: false,
              showAdditionalInputContent: false,
              additionalInputContent: '',
              isAdditonalInputAfter: true,
              draggable: false,
            },
          ],
          type: '',
          name: 'wrapper_736',
          classname: 'form-group',
          wrapperType: 'BOX',
          id: 'wrapper_7344',
          fields: [],
          head: '',
          tabHeadClass: '',
          draggable: false,
        },
      ],
      head: '',
      tabHeadClass: '',
      draggable: false,
    },
  ],
  type: '',
  classname: '',
},
  //User mgt Filter
  {
    jsonId: 98,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_963',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_517',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'first_name',
                id: 'first_name',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'First Name',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_39',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_72',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'last_name',
                id: 'last_name',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Last Name',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_421',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_809',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [
              {
                type: 'text',
                name: 'email',
                id: 'email',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Email Address',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_959',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_701',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'dropdown',
                name: 'status',
                id: 'status',
                validationButtonType: '',
                classname: '',
                required: false,
                apiEndPoints: [],
                options: [
                  { id: 1, value: 'Active' },
                  { id: 2, value: 'Disabled' },
                  { id: 4, value: 'New' },
                ],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                connectedArrays: [],
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                showInputLabel: true,
                labelContent: 'Status',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_736',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_734',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  }]