import { CURRENTDATE } from "../app-constants";

export const ProjectpepJSON = [
      //Project initialization step in Plasmid Preparation and Extraction
  {
    jsonId: 66,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_576',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_790',
        fields: [
          {
            // control: [
            //   {
            //     type: 'text',
            //     name: 'project_type',
            //     id: 'project_type',
            //     value: '',
            //     placeholder: '',
            //     multiline: false,
            //     classname: 'form-control ',
            //     regularExpression: '',
            //     disabled: true,
            //     required: true,
            //     errorMessage: { noData: 'This field is required', regexFail: '' },
            //     validationButtonType: '',
            //     rows: 0,
            //     columns: 0,
            //     onDemandError: false,
            //     customValidation: [],
            //     multiError: false,
            //     applicationError: '',
            //     wrapperClassName: '',
            //     requiredErrorMessage: 'This field is required',
            //     showInputLabel: true,
            //     labelContent: 'Project Type',
            //     labelClass: 'form-label mandatory',
            //     isLabelAfter: false,
            //     showAdditionalInputContent: false,
            //     additionalInputContent: '',
            //     isAdditonalInputAfter: true,
            //     draggable: false,
            //   },
            // ],
            type: '',
            name: 'wrapper_50',
            classname: 'col-12 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_808',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'project_title',
                id: 'project_title',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'initial-submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Project Title',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_50',
            classname: 'col-12 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_808',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'project_init_by',
                id: 'project_init_by',
                validationButtonType: 'initial-submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'get',
                    url: 'user_management/users_list',
                  },
                ],
                // options: [
                //   {
                //     id: 1,
                //     value: 'admin',
                //   },
                // ],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                showInputLabel: true,
                labelContent: 'Initiated By',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_290',
            classname: 'col-12 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_654',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'project_start_date',
                id: 'project_start_date',
                validationButtonType: 'initial-submit',
                range: false,
                value: '',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: '',
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                // regularExpression:
                //   '/^(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4}(s+tos+(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4}|(,s*(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4})*)?$/',
                required: true,
                classname: '',
                errorMessage: { noData: 'This field is required', regexFail: 'Date is not valid!!' },
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: '',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'Start Date',
                labelClass: 'form-label mandatory',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_209',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_778',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'datepicker_v2',
                name: 'project_end_date',
                id: 'project_end_date',
                validationButtonType: '',
                range: false,
                value: '',
                multiple: false,
                multiAmount: 3,
                keyboardInput: false,
                startDateReadOnly: false,
                endDateReadOnly: false,
                fordisabledStart: CURRENTDATE,
                fordisabledEnd: '',
                timer: false,
                hourFormat: 24,
                placeholder: 'DD-MM-YYYY',
                startDatePlaceHolder: 'MM/DD/YYYY',
                inputClassName: 'form-control date-input',
                selectedDateClassName: 'selectedDate',
                arrowClassName: 'arrow',
                dayClassName: 'dd',
                todayClassName: 'now',
                monthClassName: 'mtn',
                // regularExpression:
                //   '/^(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4}(s+tos+(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4}|(,s*(0[1-9]|[1-2][0-9]|3[0-1])/(0[1-9]|1[0-2])/d{4})*)?$/',
                required: false,
                classname: '',
                errorMessage: { noData: 'This field is required', regexFail: 'Date is not valid!!' },
                dateFormat: 'dd/mm/yyyy',
                disabled: false,
                wrapperClassName: '',
                locale: '',
                dateSeperator: '',
                inputDateFormat: false,
                isHostParent: false,
                showInputLabel: true,
                labelContent: 'End Date',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_643',
            classname: 'col-6 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_92',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: 'form-container',
  },
  // PLASMID INFORMATION step in Plasmid Preparation and Extraction
  {
    jsonId: 67,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',

    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_871',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_17',
        fields: [
          // {
          //   control: [
          //     {
          //       type: 'dropdown',
          //       name: 'ProjectType',
          //       id: 'ProjectType',
          //       validationButtonType: '',
          //       classname: '',
          //       required: false,
          //       apiEndPoints: [],
          //       options: [],
          //       multiple: false,
          //       isClearable: false,
          //       isStatus: false,
          //       search: false,
          //       singleSelect: true,
          //       showLabel: '',
          //       errorMessage: { noData: 'This field is required' },
          //       value: '',
          //       imageUrl: '',
          //       disabled: false,
          //       wrapperClassName: '',
          //       connectedArrays: [],
          //       showBlankInPlaceholder: false,
          //       blankDataList: [],
          //       showInputLabel: true,
          //       labelContent: 'Project Type',
          //       labelClass: 'form-label',
          //       isLabelAfter: false,
          //       showAdditionalInputContent: false,
          //       additionalInputContent: '',
          //       isAdditonalInputAfter: true,
          //       draggable: false,
          //     },
          //   ],
          //   type: '',
          //   name: 'wrapper_392',
          //   classname: 'col-3 mb-3',
          //   wrapperType: 'BOX',
          //   id: 'wrapper_650',
          //   fields: [],
          //   head: '',
          //   tabHeadClass: '',
          // },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_73',
        classname: 'project-table',
        wrapperType: 'BOX',
        id: 'wrapper_364',
        fields: [
          {
            control: [],
            type: '',
            name: 'plasmidtable',
            classname: 'table plasmid-table ',
            wrapperType: 'TABLE',
            id: 'plasmidtable',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            tableFields: {
              showHead: true,
              isVertical: false,
              isIteratable: true,
              addButton: {
                classname: 'add-expand',
                name: '',
                value: '<div class="custom-tooltip"><p>Add Row</div>',
              },
              removeButton: {
                classname: 'add-minus',
                name: '',
                value: '<div class="custom-tooltip"><p>Remove Row</div>',
              },
              tableHead: {
                heading: [
                  { value: 'PLASMID TYPE', classname: '' },
                  { value: 'PLASMID', classname: '' },
                  { value: 'PLASMID ID', classname: '' },
                  { value: 'Glycerol Stock  ID', classname: '' },
                  { value: 'Vial selected', classname: '' },
                  { value: 'STORAGE LOCATION', classname: '' },
                  { value: 'ACTIONS', classname: '' },
                  { value: ' ', classname: '' },
                ],
                classname: '',
              },
              initRecordCount: 1,
              showRecordCount: false,
              recordCounterConfig: { classname: '', adjValue: '', isAdjValueAfter: false },
              tableRow: {
                name: '',
                classname: 'trclass',
                tableCols: [
                  {
                    fields: {
                      control: [
                        {
                          type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                          name: 'plasmid_type',
                          id: 'plasmid_type',
                          validationButtonType: 'plasmid-submit',
                          classname: '',
                          required: true,
                          apiEndPoints: [
                            {
                              type: 'init',
                              method: 'post',
                              url: 'inventory-plasmids/inventory-sub-types',
                              payload: {
                                perma: ['plasmids'],
                              },
                            },
                            {
                              type: 'change',
                              method: 'get',
                              Mapto: [
                                {
                                  id: 'main.wrapper_364.plasmidtable.${i}.box_936.plasmid',
                                  displayKey: 'value',
                                  urlParameterValues: { id: null },
                                },
                              ],
                              url: 'projects/bacterial-stock-plasmids-list/${id}',
                            },
                          ],
                          options: [],
                          multiple: false,
                          isClearable: false,
                          isStatus: false,
                          search: false,
                          singleSelect: true,
                          showLabel: 'value',
                          errorMessage: { noData: 'This field is required' },
                          value: '',
                          imageUrl: '',
                          disabled: false,
                          wrapperClassName: '',
                          connectedArrays: [],
                          showInputLabel: true,
                          labelContent: '',
                          labelClass: 'form-label d-none',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                      ],
                      head: '',
                      name: 'box_734',
                      id: 'box_582',
                      wrapperType: 'BOX',
                      fields: [],
                      type: '',
                      classname: '',
                    },
                    classname: '',
                  },
                  {
                    fields: {
                      control: [
                        {
                          type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                          name: 'plasmid',
                          id: 'plasmid',
                          validationButtonType: 'plasmid-submit',
                          classname: '',
                          required: true,
                          apiEndPoints: [
                            {
                              type: 'change',
                              method: 'get',
                              isGet: true,
                              Mapto: [
                                {
                                  id: 'main.wrapper_364.plasmidtable.${i}.box_614.stock_id',
                                  displayKey: 'value',
                                  urlParameterValues: { id: null },
                                },
                                // {
                                // id: 'main.wrapper_364.plasmidtable.${i}.box_716.plasmid_id',
                                // type: 'text',
                                // urlParameterValues: { plasmid_id: null },
                                // },
                              ],
                              url: 'projects/plasmids-stock-id-list/${id}',
                            },
                            {
                              type: 'change',
                              method: 'get',
                              isGet: true,
                              Mapto: [
                                {
                                  id: 'main.wrapper_364.plasmidtable.${i}.box_716.plasmid_info',
                                  displayKey: 'change',
                                  urlParameterValues: { id: null, patchProperty: 'plasmid_info' },
                                },
                                {
                                  id: 'main.wrapper_364.plasmidtable.${i}.box_716.plasmid_id',
                                  displayKey: 'change',
                                  urlParameterValues: { id: null, patchProperty: 'plasmids_id' },
                                },
                              ],
                              url: 'inventory-plasmids/${id}',
                            },
                          ],
                          options: [],
                          multiple: false,
                          isClearable: false,
                          isStatus: false,
                          search: true,
                          singleSelect: true,
                          showLabel: 'value',
                          errorMessage: { noData: 'This field is required' },
                          value: '',
                          imageUrl: '',
                          disabled: false,
                          wrapperClassName: '',
                          connectedArrays: [],
                          showInputLabel: true,
                          labelContent: 'Plasmid1',
                          labelClass: 'form-label d-none',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                        {
                          type: 'button',
                          id: 'infoicon',
                          value: '',
                          buttonType: 'plasmid_info',
                          classname: 'info',
                          divClass: '',
                          controlEndPoints: [],
                          disabled: false,
                          validationEndPoints: [],
                          invalid: false,
                          name: 'infoicon',
                          wrapperClassName: '',
                          draggable: false,
                        },
                      ],
                      name: 'box_999',
                      id: 'box_936',
                      wrapperType: 'BOX',
                      fields: [],
                      type: '',
                      classname: 'action-wrap',
                      addButton: { classname: '', value: '' },
                      removeButton: { classname: '', value: '' },
                      wrapperClassName: '',
                    },
                    classname: '',
                  },
                  {
                    fields: {
                      control: [
                        {
                          type: 'text',
                          name: 'plasmid_id',
                          id: 'plasmid_id',
                          value: '',
                          placeholder: '',
                          multiline: false,
                          classname: 'form-control ',
                          regularExpression: '',
                          disabled: true,
                          required: false,
                          errorMessage: { noData: 'This field is required', regexFail: '' },
                          validationButtonType: '',
                          rows: 0,
                          columns: 0,
                          onDemandError: false,
                          customValidation: [],
                          multiError: false,
                          applicationError: '',
                          wrapperClassName: '',
                          requiredErrorMessage: 'This field is required',
                          showInputLabel: true,
                          labelContent: 'text_232',
                          labelClass: 'form-label d-none',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },

                        {
                          type: 'text',
                          name: 'vial_info',
                          id: 'vial_info',
                          value: '',
                          placeholder: '',
                          multiline: false,
                          classname: 'd-none',
                          regularExpression: '',
                          disabled: true,
                          required: false,
                          errorMessage: { noData: 'This field is required', regexFail: '' },
                          validationButtonType: '',
                          rows: 0,
                          columns: 0,
                          onDemandError: false,
                          customValidation: [],
                          multiError: false,
                          applicationError: '',
                          wrapperClassName: '',
                          requiredErrorMessage: 'This field is required',
                          showInputLabel: true,
                          labelContent: '',
                          labelClass: 'd-none',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                        {
                          type: 'text',
                          name: 'plasmid_info',
                          id: 'plasmid_info',
                          value: '',
                          placeholder: '',
                          multiline: false,
                          classname: 'd-none',
                          regularExpression: '',
                          disabled: true,
                          required: false,
                          errorMessage: { noData: 'This field is required', regexFail: '' },
                          validationButtonType: '',
                          rows: 0,
                          columns: 0,
                          onDemandError: false,
                          customValidation: [],
                          multiError: false,
                          applicationError: '',
                          wrapperClassName: '',
                          requiredErrorMessage: 'This field is required',
                          showInputLabel: true,
                          labelContent: '',
                          labelClass: 'd-none',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                      ],
                      name: 'box_644',
                      id: 'box_716',
                      wrapperType: 'BOX',
                      fields: [],
                      type: '',
                      classname: '',
                      addButton: { classname: '', value: '' },
                      removeButton: { classname: '', value: '' },
                      wrapperClassName: '',
                    },
                    classname: '',
                  },
                  {
                    fields: {
                      control: [
                        {
                          type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                          name: 'stock_id',
                          id: 'stock_id',
                          validationButtonType: 'plasmid-submit',
                          classname: '',
                          required: true,
                          apiEndPoints: [],
                          options: [],
                          multiple: false,
                          isClearable: false,
                          isStatus: false,
                          search: false,
                          singleSelect: true,
                          showLabel: 'value',
                          errorMessage: { noData: 'This field is required' },
                          value: '',
                          imageUrl: '',
                          disabled: false,
                          wrapperClassName: '',
                          connectedArrays: [],
                          showInputLabel: true,
                          labelContent: 'stockid',
                          labelClass: 'form-label d-none',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                      ],
                      name: 'box_716',
                      id: 'box_614',
                      wrapperType: 'BOX',
                      fields: [],
                      type: '',
                      classname: '',
                      addButton: { classname: '', value: '' },
                      removeButton: { classname: '', value: '' },
                      wrapperClassName: '',
                    },
                    classname: '',
                  },
                  {
                    fields: {
                      control: [
                        {
                          type: 'text',
                          name: 'vial',
                          id: 'vial',
                          value: '',
                          placeholder: '',
                          multiline: false,
                          classname: 'form-control ',
                          regularExpression: '',
                          disabled: true,
                          required: false,
                          errorMessage: { noData: 'This field is required', regexFail: '' },
                          validationButtonType: '',
                          rows: 0,
                          columns: 0,
                          onDemandError: false,
                          customValidation: [],
                          multiError: false,
                          applicationError: '',
                          wrapperClassName: '',
                          requiredErrorMessage: 'This field is required',
                          showInputLabel: true,
                          labelContent: 'vial',
                          labelClass: 'form-label d-none',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                      ],
                      name: 'box_803',
                      id: 'box_433',
                      wrapperType: 'BOX',
                      fields: [],
                      type: '',
                      classname: '',
                      addButton: { classname: '', value: '' },
                      removeButton: { classname: '', value: '' },
                      wrapperClassName: '',
                    },
                    classname: '',
                  },
                  {
                    fields: {
                      control: [
                        {
                          type: 'button',
                          id: 'infoicon',
                          value: 'View Location',
                          buttonType: 'vial_storage',
                          classname: 'info-icon',
                          divClass: '',
                          controlEndPoints: [],
                          disabled: false,
                          validationEndPoints: [],
                          invalid: false,
                          name: 'infoicon',
                          wrapperClassName: '',
                          draggable: false,
                        },
                      ],
                      name: 'box_644',
                      id: 'box_716',
                      wrapperType: 'BOX',
                      fields: [],
                      classname: '',
                      addButton: { classname: '', value: '' },
                      removeButton: { classname: '', value: '' },
                      wrapperClassName: '',
                    },
                    classname: '',
                  },
                  {
                    fields: {
                      control: [
                        {
                          type: 'button',
                          id: 'plasmidmap',
                          value: '<div class="custom-tooltip"><p>View Plasmid Map</p></div>',
                          buttonType: 'plasmid_map',
                          classname: 'plasmid-view',
                          divClass: '',
                          controlEndPoints: [],
                          disabled: false,
                          validationEndPoints: [],
                          invalid: false,
                          name: 'plasmidmap',
                          wrapperClassName: '',
                          draggable: false,
                        },
                        {
                          type: 'button',
                          id: 'addicon',
                          value: '<div class="custom-tooltip"><p>Select Vial</p></div>',
                          buttonType: 'vial',
                          classname: 'select-vial',
                          divClass: '',
                          controlEndPoints: [],
                          disabled: false,
                          validationEndPoints: [],
                          invalid: false,
                          name: 'addicon',
                          wrapperClassName: '',
                          draggable: false,
                        },
                      ],
                      name: 'box_985',
                      id: 'box_89',
                      wrapperType: 'BOX',
                      fields: [],
                      type: '',
                      classname: 'actions',
                      addButton: { classname: '', value: '' },
                      removeButton: { classname: '', value: '' },
                      wrapperClassName: '',
                    },
                    classname: '',
                  },
                ],
              },
            },
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_133',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_329',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'exprmntal_notes',
                id: 'exprmntal_notes',
                value: '',
                placeholder: '',
                multiline: true,
                classname: 'form-control textarea',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: '',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Experimental Notes/Comments',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_588',
            classname: 'col-12',
            wrapperType: 'BOX',
            id: 'wrapper_345',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },

  // Preparation & Amplification step in Plasmid Preparation and Extraction workflow
  {
    jsonId: 68,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_871',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_17',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'protocol',
                id: 'protocol',
                validationButtonType: 'PEP-submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'get',
                    url: 'protocol/protocol-list',
                  },
                  {
                    type: 'change',
                    method: 'get',
                    Mapto: [
                      {
                        id: '',
                        displayKey: 'value',
                        urlParameterValues: { id: null },
                      },
                    ],
                    url: 'protocol/${id}',
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                connectedArrays: [],
                showInputLabel: true,
                labelContent: 'Protocol',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_392',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_650',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [],
            type: '',
            name: 'wrapper_916',
            classname: 'col-md-1 mt-4',
            wrapperType: 'BOX',
            id: 'wrapper_62',
            fields: [
              {
                control: [
                  {
                    type: 'button',
                    id: 'info',
                    value: '<div class="custom-tooltip"><p>View </p></div>',
                    buttonType: 'protocol_info',
                    classname: 'view',
                    divClass: '',
                    controlEndPoints: [],
                    disabled: false,
                    validationEndPoints: [],
                    invalid: false,
                    name: 'info',
                    wrapperClassName: '',
                    draggable: false,
                  },
                ],
                type: '',
                name: 'wrapper_913',
                classname: 'actions position-relative',
                wrapperType: 'BOX',
                id: 'wrapper_113',
                fields: [],
                head: '',
                tabHeadClass: '',
                draggable: false,
              },
            ],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        head: '',
        name: 'main',
        id: 'main',
        wrapperType: 'REGULAR',
        fields: [
          {
            control: [
              {
                type: 'label',
                name: 'Preparation',
                classname: '',
                for: '',
                required: false,
                wrapperClassName: '',
                id: 'label_198',
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_391',
            classname: 'add-heading heading-without-icon',
            wrapperType: 'BOX',
            id: 'wrapper_108',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
        ],
        type: '',
        classname: 'col-md-12',
      },
      {
        control: [],
        type: '',
        name: 'wrapper_73',
        classname: 'project-table',
        wrapperType: 'BOX',
        id: 'wrapper_364',
        fields: [
          {
            control: [],
            type: '',
            name: 'plasmidtable',
            classname: 'table prep-amplification-table',
            wrapperType: 'TABLE',
            id: 'plasmidtable',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            tableFields: {
              showHead: true,
              isVertical: false,
              isIteratable: false,
              addButton: {
                classname: 'btn btn-primary',
                name: '',
                value: '',
              },
              removeButton: {
                classname: 'btn btn-danger',
                name: '',
                value: '',
              },
              tableHead: {
                heading: [
                  { value: 'PLASMID TYPE', classname: '' },
                  { value: 'PLASMID', classname: '' },
                  { value: 'PLASMID ID', classname: '' },
                  { value: 'Glycerol Stock  ID', classname: '' },
                  { value: 'Antibiotic Resistance', classname: '' },
                  { value: 'Mammalian Resistance', classname: '' },
                ],
                classname: '',
              },
              initRecordCount: 1,
              showRecordCount: false,
              recordCounterConfig: { classname: '', adjValue: '', isAdjValueAfter: false },
              tableRow: {
                name: '',
                classname: 'trclass',
                tableCols: [
                  {
                    fields: {
                      control: [
                        {
                          type: 'text',
                          name: 'plasmid_type',
                          id: 'plasmid_type',
                          value: '',
                          placeholder: '',
                          multiline: false,
                          classname: 'form-control ',
                          regularExpression: '',
                          disabled: true,
                          required: false,
                          errorMessage: { noData: 'This field is required', regexFail: '' },
                          validationButtonType: '',
                          rows: 0,
                          columns: 0,
                          onDemandError: false,
                          customValidation: [],
                          multiError: false,
                          applicationError: '',
                          wrapperClassName: '',
                          requiredErrorMessage: 'This field is required',
                          showInputLabel: true,
                          labelContent: 'vial',
                          labelClass: 'form-label d-none',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                        {
                          type: 'text',
                          name: 'plasmid_info',
                          id: 'plasmid_info',
                          value: '',
                          placeholder: '',
                          multiline: false,
                          classname: 'd-none',
                          regularExpression: '',
                          disabled: true,
                          required: false,
                          errorMessage: {
                            noData: 'This field is required',
                            regexFail: '',
                          },
                          validationButtonType: '',
                          rows: 0,
                          columns: 0,
                          onDemandError: false,
                          customValidation: [],
                          multiError: false,
                          applicationError: '',
                          wrapperClassName: '',
                          requiredErrorMessage: 'This field is required',
                          showInputLabel: true,
                          labelContent: '',
                          labelClass: 'd-none',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                      ],
                      head: '',
                      name: 'box_734',
                      id: 'box_582',
                      wrapperType: 'BOX',
                      fields: [],
                      type: '',
                      classname: '',
                    },
                    classname: '',
                  },
                  {
                    fields: {
                      control: [
                        {
                          type: 'text',
                          name: 'plasmid',
                          id: 'plasmid',
                          value: '',
                          placeholder: '',
                          multiline: false,
                          classname: 'form-control ',
                          regularExpression: '',
                          disabled: true,
                          required: false,
                          errorMessage: { noData: 'This field is required', regexFail: '' },
                          validationButtonType: '',
                          rows: 0,
                          columns: 0,
                          onDemandError: false,
                          customValidation: [],
                          multiError: false,
                          applicationError: '',
                          wrapperClassName: '',
                          requiredErrorMessage: 'This field is required',
                          showInputLabel: true,
                          labelContent: 'vial',
                          labelClass: 'form-label d-none',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                        {
                          type: 'button',
                          id: 'infoicon',
                          value: '',
                          buttonType: 'plasmid_info',
                          classname: 'info',
                          divClass: '',
                          controlEndPoints: [],
                          disabled: false,
                          validationEndPoints: [],
                          invalid: false,
                          name: 'infoicon',
                          wrapperClassName: '',
                          draggable: false,
                        },
                        {
                          type: 'button',
                          id: 'infoiconview',
                          value: '',
                          buttonType: 'plasmid_map',
                          classname: 'plasmid-view',
                          divClass: '',
                          controlEndPoints: [],
                          disabled: false,
                          validationEndPoints: [],
                          invalid: false,
                          name: 'infoicon',
                          wrapperClassName: '',
                          draggable: false,
                        },
                      ],
                      name: 'box_999',
                      id: 'box_936',
                      wrapperType: 'BOX',
                      fields: [],
                      type: '',
                      classname: 'plasmid d-flex',
                      addButton: { classname: '', value: '' },
                      removeButton: { classname: '', value: '' },
                      wrapperClassName: '',
                    },
                    classname: '',
                  },
                  {
                    fields: {
                      control: [
                        {
                          type: 'text',
                          name: 'inv_item_code',
                          id: 'inv_item_code',
                          value: '',
                          placeholder: '',
                          multiline: false,
                          classname: 'form-control ',
                          regularExpression: '',
                          disabled: true,
                          required: false,
                          errorMessage: { noData: 'This field is required', regexFail: '' },
                          validationButtonType: '',
                          rows: 0,
                          columns: 0,
                          onDemandError: false,
                          customValidation: [],
                          multiError: false,
                          applicationError: '',
                          wrapperClassName: '',
                          requiredErrorMessage: 'This field is required',
                          showInputLabel: true,
                          labelContent: 'vial',
                          labelClass: 'form-label d-none',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                      ],
                      name: 'box_644',
                      id: 'box_716',
                      wrapperType: 'BOX',
                      fields: [],
                      type: '',
                      classname: '',
                      addButton: { classname: '', value: '' },
                      removeButton: { classname: '', value: '' },
                      wrapperClassName: '',
                    },
                    classname: '',
                  },
                  {
                    fields: {
                      control: [
                        {
                          type: 'text',
                          name: 'stock_id',
                          id: 'stock_id',
                          value: '',
                          placeholder: '',
                          multiline: false,
                          classname: 'form-control ',
                          regularExpression: '',
                          disabled: true,
                          required: false,
                          errorMessage: { noData: 'This field is required', regexFail: '' },
                          validationButtonType: '',
                          rows: 0,
                          columns: 0,
                          onDemandError: false,
                          customValidation: [],
                          multiError: false,
                          applicationError: '',
                          wrapperClassName: '',
                          requiredErrorMessage: 'This field is required',
                          showInputLabel: true,
                          labelContent: 'vial',
                          labelClass: 'form-label d-none',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                      ],
                      name: 'box_716',
                      id: 'box_614',
                      wrapperType: 'BOX',
                      fields: [],
                      type: '',
                      classname: '',
                      addButton: { classname: '', value: '' },
                      removeButton: { classname: '', value: '' },
                      wrapperClassName: '',
                    },
                    classname: '',
                  },
                  {
                    fields: {
                      control: [
                        {
                          type: 'text',
                          name: 'bacterial_resistance',
                          id: 'bacterial_resistance',
                          value: '',
                          placeholder: '',
                          multiline: false,
                          classname: 'form-control ',
                          regularExpression: '',
                          disabled: true,
                          required: false,
                          errorMessage: { noData: 'This field is required', regexFail: '' },
                          validationButtonType: '',
                          rows: 0,
                          columns: 0,
                          onDemandError: false,
                          customValidation: [],
                          multiError: false,
                          applicationError: '',
                          wrapperClassName: '',
                          requiredErrorMessage: 'This field is required',
                          showInputLabel: true,
                          labelContent: 'vial',
                          labelClass: 'form-label d-none',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                      ],
                      name: 'box_803',
                      id: 'box_433',
                      wrapperType: 'BOX',
                      fields: [],
                      type: '',
                      classname: '',
                      addButton: { classname: '', value: '' },
                      removeButton: { classname: '', value: '' },
                      wrapperClassName: '',
                    },
                    classname: '',
                  },
                  {
                    fields: {
                      control: [
                        {
                          type: 'text',
                          name: 'mammalian_resistance',
                          id: 'mammalian_resistance',
                          value: '',
                          placeholder: '',
                          multiline: false,
                          classname: 'form-control ',
                          regularExpression: '',
                          disabled: true,
                          required: false,
                          errorMessage: { noData: 'This field is required', regexFail: '' },
                          validationButtonType: '',
                          rows: 0,
                          columns: 0,
                          onDemandError: false,
                          customValidation: [],
                          multiError: false,
                          applicationError: '',
                          wrapperClassName: '',
                          requiredErrorMessage: 'This field is required',
                          showInputLabel: true,
                          labelContent: 'vial',
                          labelClass: 'form-label d-none',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                      ],
                      name: 'box_803',
                      id: 'box_433',
                      wrapperType: 'BOX',
                      fields: [],
                      type: '',
                      classname: '',
                      addButton: { classname: '', value: '' },
                      removeButton: { classname: '', value: '' },
                      wrapperClassName: '',
                    },
                    classname: '',
                  },
                ],
              },
            },
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        head: '',
        name: 'main',
        id: 'main',
        wrapperType: 'REGULAR',
        fields: [
          {
            control: [
              {
                type: 'label',
                name: 'Bacterial Strain and Amplification Info',
                classname: '',
                for: '',
                required: false,
                wrapperClassName: '',
                id: 'label_198',
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_391',
            classname: 'add-heading heading-without-icon',
            wrapperType: 'BOX',
            id: 'wrapper_108',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
        ],
        type: '',
        classname: 'col-md-12',
      },
      {
        control: [],
        type: '',
        name: 'wrapper_794',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_721',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'bacterial_strain',
                id: 'bacterial_strain',
                validationButtonType: 'PEP-submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'inventory_item',
                    payload: {
                      user_input: '',
                      perma: 'bacterial-strains',
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                connectedArrays: [],
                showInputLabel: true,
                labelContent: 'Bacterial Strain',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_525',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_675',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'cell_cult_media',
                id: 'cell_cult_media',
                validationButtonType: 'PEP-submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'inventory_item',
                    payload: {
                      user_input: '',
                      perma: 'cell-culture-media',
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                connectedArrays: [],
                showInputLabel: true,
                labelContent: 'Media',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_157',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_286',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'incubation_time',
                id: 'incubation_time',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'PEP-submit',
                rows: 0,
                columns: 0,
                onDemandError: true,
                customValidation: [{ regularExpression: /^\d+(\.\d+)?$/, errorMessage: 'Please type a number' },],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Incubation  Time(h)',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_433',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_498',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'temperature',
                id: 'temperature',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'PEP-submit',
                rows: 0,
                columns: 0,
                onDemandError: true,
                customValidation: [{ regularExpression: /^-?\d+(\.\d+)?$/, errorMessage: 'Please Type a Number' }],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Temperature(°C)',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_87',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_102',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        type: '',
        name: 'wrapper_133',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_329',
        fields: [
          {
            control: [
              {
                type: 'text',
                name: 'experimental_notes',
                id: 'experimental_notes',
                value: '',
                placeholder: '',
                multiline: true,
                classname: 'form-control textarea',
                regularExpression: '',
                disabled: false,
                required: false,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'PEP-submit',
                rows: 0,
                columns: 0,
                onDemandError: false,
                customValidation: [],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Experimental Notes/Comments',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_588',
            classname: 'col-12',
            wrapperType: 'BOX',
            id: 'wrapper_345',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  // Extraction step in Plasmid Preparation and Extraction workflow
  {
    jsonId: 69,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',

    fields: [
      {
        control: [],
        type: '',
        name: 'wrapper_871',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_17',
        fields: [
          {
            control: [
              {
                type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'protocol',
                id: 'protocol',
                validationButtonType: 'EXT-submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'get',
                    url: 'protocol/protocol-list',
                  },
                  {
                    type: 'change',
                    method: 'get',
                    Mapto: [
                      {
                        id: '',
                        displayKey: 'value',
                        urlParameterValues: { id: null },
                      },
                    ],
                    url: 'protocol/${id}',
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                connectedArrays: [],
                showInputLabel: true,
                labelContent: 'Protocol',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_392',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_650',
            fields: [],
            head: '',
            tabHeadClass: '',
          },
          {
            control: [],
            type: '',
            name: 'wrapper_916',
            classname: 'col-md-3 mt-4',
            wrapperType: 'BOX',
            id: 'wrapper_62',
            fields: [
              {
                control: [
                  {
                    type: 'button',
                    id: 'view',
                    value: '<div class="custom-tooltip"><p>View </p></div>',
                    buttonType: 'viewProtocol',
                    classname: 'view',
                    divClass: '',
                    controlEndPoints: [],
                    disabled: false,
                    validationEndPoints: [],
                    invalid: false,
                    name: 'view',
                    wrapperClassName: '',
                    draggable: false,
                  },
                ],
                type: '',
                name: 'wrapper_913',
                classname: 'actions',
                wrapperType: 'BOX',
                id: 'wrapper_113',
                fields: [],
                head: '',
                tabHeadClass: '',
                draggable: false,
              },
            ],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        head: '',
        name: 'main',
        id: 'main',
        wrapperType: 'REGULAR',
        fields: [
          {
            control: [
              {
                type: 'label',
                name: 'Plasmid Extraction',
                classname: '',
                for: '',
                required: false,
                wrapperClassName: '',
                id: 'label_198',
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_391',
            classname: 'add-heading heading-without-icon',
            wrapperType: 'BOX',
            id: 'wrapper_108',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
        ],
        type: '',
        classname: 'col-md-12',
      },
      {
        control: [],
        type: '',
        name: 'wrapper_794',
        classname: 'row',
        wrapperType: 'BOX',
        id: 'wrapper_721',

        fields: [
          {
            control: [
              {
                type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                name: 'extraction_kit',
                id: 'ExtractionKit',
                validationButtonType: 'EXT-submit',
                classname: '',
                required: true,
                apiEndPoints: [
                  {
                    type: 'init',
                    method: 'post',
                    url: 'inventory_item',
                    payload: {
                      sub_type_id: 0,
                      user_input: '',
                      perma: 'assay-kits',
                    },
                  },
                ],
                options: [],
                multiple: false,
                isClearable: false,
                isStatus: false,
                search: false,
                singleSelect: true,
                showLabel: 'value',
                errorMessage: { noData: 'This field is required' },
                value: '',
                imageUrl: '',
                disabled: false,
                wrapperClassName: '',
                connectedArrays: [],
                showInputLabel: true,
                labelContent: 'Extraction Kit',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: '',
                isAdditonalInputAfter: true,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_525',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_675',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
          {
            control: [
              {
                type: 'text',
                name: 'elution_vol',
                id: 'elution_vol',
                value: '',
                placeholder: '',
                multiline: false,
                classname: 'form-control ',
                regularExpression: '',
                disabled: false,
                required: true,
                errorMessage: { noData: 'This field is required', regexFail: '' },
                validationButtonType: 'EXT-submit',
                rows: 0,
                columns: 0,
                onDemandError: true,
                customValidation: [
                  {
                    regularExpression: "/^(0\.[1-9]+|[1-9]\d*(\.\d+)?)$/",
                    errorMessage: 'Please type a valid number',
                  },
                ],
                multiError: false,
                applicationError: '',
                wrapperClassName: '',
                requiredErrorMessage: 'This field is required',
                showInputLabel: true,
                labelContent: 'Plasmid  Eluted  Volume(µL)',
                labelClass: 'form-label',
                isLabelAfter: false,
                showAdditionalInputContent: false,
                additionalInputContent: false,
                isAdditonalInputAfter: false,
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_157',
            classname: 'col-3 mb-3',
            wrapperType: 'BOX',
            id: 'wrapper_286',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [],
        head: '',
        name: 'main',
        id: 'main',
        wrapperType: 'REGULAR',
        fields: [
          {
            control: [
              {
                type: 'label',
                name: 'QC',
                classname: '',
                for: '',
                required: false,
                wrapperClassName: '',
                id: 'label_198',
                draggable: false,
              },
            ],
            type: '',
            name: 'wrapper_391',
            classname: 'add-heading heading-without-icon',
            wrapperType: 'BOX',
            id: 'wrapper_108',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
          },
        ],
        type: '',
        classname: 'col-md-12',
      },
      {
        control: [],
        type: '',
        name: 'wrapper_73',
        classname: 'plasmid-table',
        wrapperType: 'BOX',
        id: 'wrapper_364',
        fields: [
          {
            control: [],
            type: '',
            name: 'plasmidtable',
            classname: 'table qc-table',
            wrapperType: 'TABLE',
            id: 'plasmidtable',
            fields: [],
            head: '',
            tabHeadClass: '',
            draggable: false,
            tableFields: {
              showHead: true,
              isVertical: false,
              isIteratable: false,
              addButton: {
                classname: 'btn btn-primary',
                name: '',
                value:
                  '<?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo,\nwww.svgrepo.com\n, Generator: SVG Repo Mixer Tools -->\n<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="\nhttp://www.w3.org/2000/svg">\n<circle\ncx="12" cy="12" r="9" stroke="#222222"/>\n<path d="M12 15L12 9" stroke="#222222" stroke-linecap="square"/>\n<path d="M15 12L9 12" stroke="#222222" stroke-linecap="square"/>\n</svg>',
              },
              removeButton: {
                classname: 'btn btn-danger',
                name: '',
                value:
                  '<?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo,\nwww.svgrepo.com\n, Generator: SVG Repo Mixer Tools -->\n<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="\nhttp://www.w3.org/2000/svg">\n<path\nd="M4 7H20" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n<path d="M6 10L7.70141 19.3578C7.87432 20.3088 8.70258 21 9.66915 21H14.3308C15.2974 21 16.1257 20.3087 16.2986 19.3578L18 10" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n<path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n</svg>',
              },
              tableHead: {
                heading: [
                  { value: 'PLASMID', classname: '' },
                  { value: 'QC Status', classname: '' },
                  { value: 'A260', classname: '' },
                  { value: 'A260/280', classname: '' },
                  { value: 'A260/230 ', classname: '' },
                  { value: 'Yield<span class="text-lowercase">(µg/µl)</span>', classname: '' },
                  { value: 'NO OF VIALS	', classname: '' },
                ],
                classname: '',
              },
              initRecordCount: 1,
              showRecordCount: false,
              recordCounterConfig: { classname: '', adjValue: '', isAdjValueAfter: false },
              tableRow: {
                name: '',
                classname: 'trclass',
                tableCols: [
                  {
                    fields: {
                      control: [
                        {
                          type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                          name: 'plasmid1',
                          id: 'plasmid1',
                          validationButtonType: 'EXT-submit',
                          classname: '',
                          required: true,
                          apiEndPoints: [],
                          options: [],
                          multiple: false,
                          isClearable: false,
                          isStatus: false,
                          search: false,
                          singleSelect: true,
                          showLabel: 'value',
                          errorMessage: { noData: 'This field is required' },
                          value: '',
                          imageUrl: '',
                          disabled: true,
                          wrapperClassName: '',
                          connectedArrays: [],
                          showInputLabel: true,
                          labelContent: 'Plasmid1',
                          labelClass: 'form-label d-none',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                      ],
                      name: 'box_999',
                      id: 'box_936',
                      wrapperType: 'BOX',
                      fields: [],
                      type: '',
                      classname: '',
                      addButton: { classname: '', value: '' },
                      removeButton: { classname: '', value: '' },
                      wrapperClassName: '',
                    },
                    classname: '',
                  },
                  {
                    fields: {
                      control: [
                        {
                          type: 'dropdown',
 showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                          name: 'qc_status',
                          id: 'status',
                          validationButtonType: 'EXT-submit',
                          classname: '',
                          required: true,
                          apiEndPoints: [],
                          options: [
                            { id: 1, value: 'Passed' },
                            { id: 2, value: 'Failed' },
                          ],
                          multiple: false,
                          isClearable: false,
                          isStatus: false,
                          search: false,
                          singleSelect: true,
                          showLabel: 'value',
                          errorMessage: { noData: 'This field is required' },
                          value: '',
                          imageUrl: '',
                          disabled: false,
                          wrapperClassName: '',
                          connectedArrays: [],
                          showInputLabel: false,
                          labelContent: 'Status',
                          labelClass: 'form-label d-none',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                      ],
                      name: 'box_716',
                      id: 'box_614',
                      wrapperType: 'BOX',
                      fields: [],
                      type: '',
                      classname: '',
                      addButton: { classname: '', value: '' },
                      removeButton: { classname: '', value: '' },
                      wrapperClassName: '',
                    },
                    classname: '',
                  },
                  {
                    fields: {
                      control: [
                        {
                          type: 'text',
                          name: 'qc_a260',
                          id: 'textBox_a260',
                          value: '',
                          placeholder: '',
                          multiline: false,
                          classname: 'form-control ',
                          regularExpression: '',
                          disabled: false,
                          required: true,
                          errorMessage: { noData: 'This field is required', regexFail: '' },
                          validationButtonType: 'EXT-submit',
                          rows: 0,
                          columns: 0,
                          onDemandError: true,
                          customValidation: [
                            {
                              regularExpression: "/^(0\.[1-9]+|[1-9]\d*(\.\d+)?)$/",
                              errorMessage: 'invalid number',
                            },
                          ],
                          multiError: false,
                          applicationError: '',
                          wrapperClassName: '',
                          requiredErrorMessage: 'This field is required',
                          showInputLabel: true,
                          labelContent: 'input_i_a260',
                          labelClass: 'form-label d-none',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                        
                      ],
                      name: 'box_803',
                      id: 'box_433',
                      wrapperType: 'BOX',
                      fields: [],
                      type: '',
                      classname: '',
                      addButton: { classname: '', value: '' },
                      removeButton: { classname: '', value: '' },
                      wrapperClassName: '',
                    },
                    classname: '',
                  },
                  {
                    fields: {
                      control: [
                        {
                          type: 'text',
                          name: 'qc_260_280',
                          id: 'textBox_280',
                          value: '',
                          placeholder: '',
                          multiline: false,
                          classname: 'form-control ',
                          regularExpression: '',
                          disabled: false,
                          required: true,
                          errorMessage: { noData: 'This field is required', regexFail: '' },
                          validationButtonType: 'EXT-submit',
                          rows: 0,
                          columns: 0,
                          onDemandError: true,
                          customValidation: [
                            {
                              regularExpression: "/^(0\.[1-9]+|[1-9]\d*(\.\d+)?)$/",
                              errorMessage: 'invalid number',
                            },
                          ],
                          multiError: false,
                          applicationError: '',
                          wrapperClassName: '',
                          requiredErrorMessage: 'This field is required',
                          showInputLabel: true,
                          labelContent: 'input_a260/280',
                          labelClass: 'form-label d-none',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                      ],
                      name: 'box_82',
                      id: 'box_387',
                      wrapperType: 'BOX',
                      fields: [],
                      type: '',
                      classname: '',
                      addButton: { classname: '', value: '' },
                      removeButton: { classname: '', value: '' },
                      wrapperClassName: '',
                    },
                    classname: '',
                  },
                  {
                    fields: {
                      control: [
                        {
                          type: 'text',
                          name: 'qc_260_230',
                          id: 'textBox_230',
                          value: '',
                          placeholder: '',
                          multiline: false,
                          classname: 'form-control ',
                          regularExpression: '',
                          disabled: false,
                          required: true,
                          errorMessage: { noData: 'This field is required', regexFail: '' },
                          validationButtonType: 'EXT-submit',
                          rows: 0,
                          columns: 0,
                          onDemandError: true,
                          customValidation: [
                            {
                              regularExpression: "/^(0\.[1-9]+|[1-9]\d*(\.\d+)?)$/",
                              errorMessage: 'invalid number',
                            },
                          ],
                          multiError: false,
                          applicationError: '',
                          wrapperClassName: '',
                          requiredErrorMessage: 'This field is required',
                          showInputLabel: true,
                          labelContent: 'text_843',
                          labelClass: 'form-label d-none',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                      ],
                      name: 'box_420',
                      id: 'box_747',
                      wrapperType: 'BOX',
                      fields: [],
                      type: '',
                      classname: '',
                      addButton: { classname: '', value: '' },
                      removeButton: { classname: '', value: '' },
                      wrapperClassName: '',
                    },
                    classname: '',
                  },
                  {
                    fields: {
                      control: [
                        {
                          type: 'text',
                          name: 'yield_vol',
                          id: 'textBox_yeild',
                          value: '',
                          placeholder: '',
                          multiline: false,
                          classname: 'form-control ',
                          regularExpression: '',
                          disabled: false,
                          required: true,
                          errorMessage: { noData: 'This field is required', regexFail: '' },
                          validationButtonType: 'EXT-submit',
                          rows: 0,
                          columns: 0,
                          onDemandError: true,
                          customValidation: [
                            {
                              regularExpression: "/^(0\.[1-9]+|[1-9]\d*(\.\d+)?)$/",
                              errorMessage: 'Please type a valid number',
                            },
                          ],
                          multiError: false,
                          applicationError: '',
                          wrapperClassName: '',
                          requiredErrorMessage: 'This field is required',
                          showInputLabel: true,
                          labelContent: 'text_489',
                          labelClass: 'form-label d-none',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                      ],
                      name: 'box_540',
                      id: 'box_822',
                      wrapperType: 'BOX',
                      fields: [],
                      type: '',
                      classname: '',
                      addButton: { classname: '', value: '' },
                      removeButton: { classname: '', value: '' },
                      wrapperClassName: '',
                    },
                    classname: '',
                  },
                  {
                    fields: {
                      control: [
                        {
                          type: 'text',
                          name: 'no_of_vials',
                          id: 'no_of_vials',
                          value: '',
                          placeholder: '',
                          multiline: false,
                          classname: 'form-control ',
                          regularExpression: '',
                          disabled: false,
                          required: true,
                          errorMessage: { noData: 'This field is required', regexFail: '' },
                          validationButtonType: 'EXT-submit',
                          rows: 0,
                          columns: 0,
                          onDemandError: true,
                          customValidation: [
                            {
                              regularExpression: "/^[1-9][0-9]*$/",
                              errorMessage: 'Please type a valid non zero integer',
                            },
                          ],
                          multiError: false,
                          applicationError: '',
                          wrapperClassName: '',
                          requiredErrorMessage: 'This field is required',
                          showInputLabel: false,
                          labelContent: '',
                          labelClass: 'form-label d-none',
                          isLabelAfter: false,
                          showAdditionalInputContent: false,
                          additionalInputContent: '',
                          isAdditonalInputAfter: true,
                          draggable: false,
                        },
                      ],
                      name: 'box_540',
                      id: 'box_822',
                      wrapperType: 'BOX',
                      fields: [],
                      type: '',
                      classname: '',
                      wrapperClassName: '',
                    },
                    classname: '',
                  },
                ],
              },
            },
          },
        ],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
 // Plasmid Extraction and Purification work flow extraction file upload 
  {
    jsonId: 81,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {
        control: [
          {
            type: 'dropdown',
            showBlankInPlaceholder: true,
            blankDataList: ['""', 'undefined', 'null'],
            name: 'file_type',
            id: 'file_type',
            validationButtonType: 'upload',
            classname: '',
            required: true,
            apiEndPoints: [],
            options: [
              {
                key: 1,
                value: 'Absorbance Data',
                data: 'project_data',
              },
              {
                key: 2,
                value: 'Gel Image',
                data: 'gel_image',
              },
              {
                key: 3,
                value: 'Plasmid Sequence',
                data: 'plasmid_sequence_fasta_format',
              },
            ],
            multiple: false,
            isClearable: false,
            isStatus: false,
            search: false,
            singleSelect: true,
            showLabel: 'value',
            errorMessage: { noData: 'This field is required' },
            value: '',
            imageUrl: '',
            disabled: false,
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Select File Type',
            labelClass: 'form-label',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_801',
        classname: 'col-12 mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_766',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'file',
            name: 'file',
            id: 'file',
            value: 'upload',
            classname: 'btn btn-file',
            fileNameShow: true,
            size: 10,
            url: '',
            functionName: '',
            wrapperClassName: '',
            fileCount: 1,
            sizeKB: '50000',
            allowMultiple: false,
            required: true,
            validationButtonType: 'upload',
            formDataType: 'blob',
            errorMsg: { invalidFormat: 'Invalid File Format', invalidSize: 'File Size exceeded' },
            fileTypes: ['pdf', 'jpeg', 'png', 'jpg', 'xlsx', 'txt','fasta', 'fas', 'fa', 'fna', 'ffn', 'faa', 'mpfa', 'frn'],
            listClass: '',
            mainClass: '',
            btnDivClass: '',
            showInputLabel: true,
            labelContent: 'Drag and drop files here or browse files  ',
            labelClass: 'form-label',
            requiredErrorMessage: 'This field is required',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
            showViewIcon: false,
            showDownloadIcon: false,
          },
        ],
        type: '',
        name: 'wrapper_379',
        classname: 'col-12 mb-3',
        wrapperType: 'BOX',
        id: 'wrapper_461',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
]