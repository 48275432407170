import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Toast } from '../interface/Toast';
import { AlertInfo, AlertInfoTrigger } from '../interface/alert-message';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  constructor() {}

  public toasterTigger$: Subject<Toast> = new Subject();
  public confirmTrigger$: Subject<AlertInfoTrigger> = new Subject();
  public confirmDeleteTigger$: Subject<any> = new Subject();

  public alert = {
    toast: (toast: Toast): void => {
      toast.message = toast.message ?? 'Something bad happened; please try again later';
      this.toasterTigger$.next(toast);
    },
    confirm: (config: AlertInfo): Promise<boolean> => {
      return new Promise((resolve, reject) => {
        let payload: AlertInfoTrigger = { ...config, resolve };
        this.confirmTrigger$.next(payload);
      });
    },
    confirmDelete: (config: any): Promise<boolean> => {
      return new Promise((resolve, reject) => {
        let payload: any = { ...config, resolve };
        this.confirmDeleteTigger$.next(payload);
      });
    },
  };
}
