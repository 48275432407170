//login
export const LoginDynamicJSON = [
  //login
  {
    "jsonId": 1,
    "control": [],
    "head": "",
    "name": "main",
    "id": "main",
    "wrapperType": "REGULAR",
    "fields": [
      {
        "control": [
          {
            "type": "text",
            "name": "username",
            "id": "username",
            "value": "",
            "placeholder": "",
            "multiline": false,
            "classname": "form-control ",
            "regularExpression": "",
            "disabled": false,
            "required": true,
            "requiredErrorMessage": "Username is required",
            "errorMessage": {
              "noData": "Username is required",
              "regexFail": ""
            },
            "validationButtonType": "signIn",
            "rows": 0,
            "columns": 0,
            "onDemandError": false,
            "customValidation": [
              {
                regularExpression:
                  /^(([^<>()\[\]\\.,.;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                "errorMessage": "Please enter a valid email"
              }
            ],
            "multiError": false,
            "applicationError": "",
            "wrapperClassName": "",
            "showInputLabel": true,
            "labelContent": "User Name",
            "labelClass": "form-label",
            "isLabelAfter": false,
            "showAdditionalInputContent": false,
            "additionalInputContent": "",
            "isAdditonalInputAfter": true,
            "draggable": false
          }
        ],
        "type": "",
        "name": "wrapper_448",
        "classname": "col-12 mb-3",
        "wrapperType": "BOX",
        "id": "wrapper_193",
        "fields": [],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      },
      {
        "control": [
          {
            "type": "password",
            "name": "password",
            "id": "password",
            "value": "",
            "placeholder": "",
            "multiline": false,
            "classname": "form-control ",
            "regularExpression": "",
            "disabled": false,
            "required": true,
            "requiredErrorMessage": "Password is required",
            "errorMessage": {
              "noData": "Password is required",
              "regexFail": ""
            },
            "validationButtonType": "signIn",
            "rows": 0,
            "columns": 0,
            "onDemandError": false,
            "customValidation": [],
            "multiError": false,
            "applicationError": "",
            "wrapperClassName": "",
            "showInputLabel": true,
            "labelContent": "Password",
            "labelClass": "form-label",
            "isLabelAfter": false,
            "showAdditionalInputContent": false,
            "additionalInputContent": "",
            "isAdditonalInputAfter": true,
            "draggable": false
          }
        ],
        "type": "",
        "name": "wrapper_718",
        "classname": "col-12 mb-3",
        "wrapperType": "BOX",
        "id": "wrapper_167",
        "fields": [],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      }
    ],
    "type": "",
    "classname": ""
  },
  //Reset password
  {
    "jsonId": 102,
    "control": [

    ],
    "head": "",
    "name": "main",
    "id": "main",
    "wrapperType": "REGULAR",
    "fields": [
      {
        "control": [
          {
            "type": "password",
            "name": "new_password",
            "id": "new_password",
            "value": "",
            "required": true,
            "requiredErrorMessage": "This field is required",
            "validationButtonType": "change-pass",
            "placeholder": "",
            "multiline": false,
            "classname": "form-control ",
            "disabled": false,
            "rows": 0,
            "columns": 0,
            "onDemandError": true,
            "customValidation": [
              {
                "regularExpression": "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}:<>?])(?!.*\\s).{8,16}$",
                "errorMessage": "Please enter a valid password !"
              }
            ],
            "multiError": false,
            "showInputLabel": true,
            "labelContent": "Enter New Password <span class=\"mandatory-info\">*</span> <div class=\"info info-password\"><div class=\"custom-tooltip\"><p>Password should contain :<br>At least 8 characters<br>At least one number<br>At least one lowercase and uppercase letter<br>At least one special character </p></div></div>",
            "labelClass": "form-label",
            "isLabelAfter": false,
            "showAdditionalInputContent": false,
            "additionalInputContent": "",
            "isAdditonalInputAfter": true,
            "wrapperClassName": "",
            "draggable": false
          }
        ],
        "type": "",
        "name": "wrapper_584",
        "classname": "form-group",
        "wrapperType": "BOX",
        "id": "wrapper_299",
        "fields": [

        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      },
      {
        "control": [
          {
            "type": "password",
            "name": "confirm_password",
            "id": "confirm_password",
            "value": "",
            "required": true,
            "requiredErrorMessage": "This field is required",
            "validationButtonType": "change-pass",
            "placeholder": "",
            "multiline": false,
            "classname": "form-control ",
            "disabled": false,
            "rows": 0,
            "columns": 0,
            "customValidation": [
              {
                "regularExpression": "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}:<>?])(?!.*\\s).{8,16}$",
                "errorMessage": "Please enter a valid password !"
              }
            ],
            "onDemandError": true,
            "multiError": false,
            "showInputLabel": true,
            "labelContent": "Confirm New Password <span class=\"mandatory-info\">*</span> ",
            "labelClass": "form-label",
            "isLabelAfter": false,
            "showAdditionalInputContent": false,
            "additionalInputContent": "",
            "isAdditonalInputAfter": true,
            "wrapperClassName": "",
            "draggable": false
          }
        ],
        "type": "",
        "name": "wrapper_946",
        "classname": "form-group",
        "wrapperType": "BOX",
        "id": "wrapper_547",
        "fields": [

        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      }
    ],
    "type": "",
    "classname": ""
  },
  //account activation
  {
    "jsonId": 103,
    "control": [],
    "head": "",
    "name": "main",
    "id": "main",
    "wrapperType": "REGULAR",
    "fields": [
      {
        "control": [
          {
            "type": "password",
            "name": "temp_password",
            "id": "temp_password",
            "value": "",
            "required": true,
            "requiredErrorMessage": "This field is required",
            "validationButtonType": "acc-activate",
            "placeholder": "",
            "multiline": false,
            "classname": "form-control ",
            "disabled": false,
            "rows": 0,
            "columns": 0,
            "customValidation": [

            ],
            "multiError": false,
            "onDemandError": false,
            "showInputLabel": true,
            "labelContent": "Temporary Password",
            "labelClass": "form-label mandatory",
            "isLabelAfter": false,
            "showAdditionalInputContent": false,
            "additionalInputContent": "",
            "isAdditonalInputAfter": true,
            "wrapperClassName": "",
            "draggable": false
          }
        ],
        "type": "",
        "name": "wrapper_935",
        "classname": "form-group",
        "wrapperType": "BOX",
        "id": "wrapper_276",
        "fields": [

        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      },
      {
        "control": [
          {
            "type": "password",
            "name": "new_password",
            "id": "new_password",
            "value": "",
            "required": true,
            "requiredErrorMessage": "This field is required",
            "validationButtonType": "acc-activate",
            "placeholder": "",
            "multiline": false,
            "classname": "form-control ",
            "disabled": false,
            "rows": 0,
            "columns": 0,
            "onDemandError": true,
            "customValidation": [
              {
                "regularExpression": "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}:<>?])(?!.*\\s).{8,16}$",
                "errorMessage": "Please enter a valid password !"
              }
            ],
            "multiError": false,
            "showInputLabel": true,
            "labelContent": "New Password <span class=\"mandatory-info\">*</span> <div class=\"info info-password\"><div class=\"custom-tooltip\"><p>Password should contain :<br>At least 8 characters<br>At least one number<br>At least one lowercase and uppercase letter<br>At least one special character </p></div></div>",
            "labelClass": "form-label",
            "isLabelAfter": false,
            "showAdditionalInputContent": false,
            "additionalInputContent": "",
            "isAdditonalInputAfter": true,
            "wrapperClassName": "",
            "draggable": false
          }
        ],
        "type": "",
        "name": "wrapper_584",
        "classname": "form-group",
        "wrapperType": "BOX",
        "id": "wrapper_299",
        "fields": [

        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      },
      {
        "control": [
          {
            "type": "password",
            "name": "confirm_password",
            "id": "confirm_password",
            "value": "",
            "required": true,
            "requiredErrorMessage": "This field is required",
            "validationButtonType": "acc-activate",
            "placeholder": "",
            "multiline": false,
            "classname": "form-control ",
            "disabled": false,
            "rows": 0,
            "columns": 0,
            "customValidation": [

            ],
            "onDemandError": true,
            "multiError": false,
            "showInputLabel": true,
            "labelContent": "Confirm New Password <span class=\"mandatory-info\">*</span> ",
            "labelClass": "form-label",
            "isLabelAfter": false,
            "showAdditionalInputContent": false,
            "additionalInputContent": "",
            "isAdditonalInputAfter": true,
            "wrapperClassName": "",
            "draggable": false
          }
        ],
        "type": "",
        "name": "wrapper_946",
        "classname": "form-group",
        "wrapperType": "BOX",
        "id": "wrapper_547",
        "fields": [

        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      }
    ],
    "type": "",
    "classname": ""
  },

  // My Profile edit form
  {
    "jsonId": 100,
    "control": [],
    "head": "",
    "name": "main",
    "id": "main",
    "wrapperType": "REGULAR",
    "fields": [
      {
        "control": [
          {
            "type": "text",
            "name": "first_name",
            "id": "first_name",
            "value": "",
            "placeholder": "",
            "multiline": false,
            "classname": "form-control ",
            "regularExpression": "",
            "disabled": false,
            "required": true,
            "errorMessage": {
              "noData": "This field is required",
              "regexFail": ""
            },
            "validationButtonType": "my-profile",
            "rows": 0,
            "columns": 0,
            "onDemandError": false,
            "customValidation": [

            ],
            "multiError": false,
            "applicationError": "",
            "wrapperClassName": "",
            "requiredErrorMessage": "This field is required",
            "showInputLabel": true,
            "labelContent": "First Name",
            "labelClass": "form-label mandatory",
            "isLabelAfter": false,
            "showAdditionalInputContent": false,
            "additionalInputContent": "",
            "isAdditonalInputAfter": true,
            "draggable": false
          }
        ],
        "type": "",
        "name": "wrapper_935",
        "classname": "form-group",
        "wrapperType": "BOX",
        "id": "wrapper_276",
        "fields": [

        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      },
      {
        "control": [
          {
            "type": "text",
            "name": "last_name",
            "id": "last_name",
            "value": "",
            "placeholder": "",
            "multiline": false,
            "classname": "form-control ",
            "regularExpression": "",
            "disabled": false,
            "required": false,
            "errorMessage": {
              "noData": "This field is required",
              "regexFail": ""
            },
            "validationButtonType": "my-profile",
            "rows": 0,
            "columns": 0,
            "onDemandError": false,
            "customValidation": [

            ],
            "multiError": false,
            "applicationError": "",
            "wrapperClassName": "",
            "showInputLabel": true,
            "labelContent": "Last Name",
            "labelClass": "form-label",
            "isLabelAfter": false,
            "showAdditionalInputContent": false,
            "additionalInputContent": "",
            "isAdditonalInputAfter": true,
            "draggable": false
          }
        ],
        "type": "",
        "name": "wrapper_584",
        "classname": "form-group",
        "wrapperType": "BOX",
        "id": "wrapper_299",
        "fields": [

        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      }
    ],
    "type": "",
    "classname": ""
  },

  //change password
  {
    "jsonId": 101,
    "control": [],
    "head": "",
    "name": "main",
    "id": "main",
    "wrapperType": "REGULAR",
    "fields": [
      {
        "control": [
          {
            "type": "password",
            "name": "previous_password",
            "id": "previous_password",
            "value": "",
            "required": true,
            "requiredErrorMessage": "This field is required",
            "validationButtonType": "change-pass",
            "placeholder": "",
            "multiline": false,
            "classname": "form-control ",
            "disabled": false,
            "rows": 0,
            "columns": 0,
            "customValidation": [

            ],
            "multiError": false,
            "onDemandError": false,
            "showInputLabel": true,
            "labelContent": "Current Password",
            "labelClass": "form-label mandatory",
            "isLabelAfter": false,
            "showAdditionalInputContent": false,
            "additionalInputContent": "",
            "isAdditonalInputAfter": true,
            "wrapperClassName": "",
            "draggable": false
          }
        ],
        "type": "",
        "name": "wrapper_935",
        "classname": "form-group",
        "wrapperType": "BOX",
        "id": "wrapper_276",
        "fields": [

        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      },
      {
        "control": [
          {
            "type": "password",
            "name": "proposed_password",
            "id": "proposed_password",
            "value": "",
            "required": true,
            "requiredErrorMessage": "This field is required",
            "validationButtonType": "change-pass",
            "placeholder": "",
            "multiline": false,
            "classname": "form-control ",
            "disabled": false,
            "rows": 0,
            "columns": 0,
            "onDemandError": true,
            "customValidation": [
              {
                "regularExpression": "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}:<>?])(?!.*\\s).{8,16}$",
                "errorMessage": "Please enter a valid password !"
              }
            ],
            "multiError": false,
            "showInputLabel": true,
            "labelContent": "New Password <span class=\"mandatory-info\">*</span> <div class=\"info info-password\"><div class=\"custom-tooltip\"><p>Password should contain :<br>At least 8 characters<br>At least one number<br>At least one lowercase and uppercase letter<br>At least one special character </p></div></div>",
            "labelClass": "form-label",
            "isLabelAfter": false,
            "showAdditionalInputContent": false,
            "additionalInputContent": "",
            "isAdditonalInputAfter": true,
            "wrapperClassName": "",
            "draggable": false
          }
        ],
        "type": "",
        "name": "wrapper_584",
        "classname": "form-group",
        "wrapperType": "BOX",
        "id": "wrapper_299",
        "fields": [

        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      },
      {
        "control": [
          {
            "type": "password",
            "name": "confirm_Password",
            "id": "confirm_Password",
            "value": "",
            "required": true,
            "requiredErrorMessage": "This field is required",
            "validationButtonType": "change-pass",
            "placeholder": "",
            "multiline": false,
            "classname": "form-control ",
            "disabled": false,
            "rows": 0,
            "columns": 0,
            "customValidation": [
              {
                "regularExpression": "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}:<>?])(?!.*\\s).{8,16}$",
                "errorMessage": "Please enter a valid password !"
              }
            ],
            "onDemandError": true,
            "multiError": false,
            "showInputLabel": true,
            "labelContent": "Confirm Password <span class=\"mandatory-info\">*</span> ",
            "labelClass": "form-label",
            "isLabelAfter": false,
            "showAdditionalInputContent": false,
            "additionalInputContent": "",
            "isAdditonalInputAfter": true,
            "wrapperClassName": "",
            "draggable": false
          }
        ],
        "type": "",
        "name": "wrapper_946",
        "classname": "form-group",
        "wrapperType": "BOX",
        "id": "wrapper_547",
        "fields": [

        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      }
    ],
    "type": "",
    "classname": ""
  },
]