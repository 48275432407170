import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InventoryDynamicJSON } from './dynamic-json/inventory-dynamic-jsons';
import { VendorDynamicJSON } from './dynamic-json/vendors-dynamic-json';
import { UsermgtDynamicJSON } from './dynamic-json/user-mgt-dynamic-jsons';
import { ProtocolDynamicJSON } from './dynamic-json/protocol-dynamic-jsons';
import { ProjectpepJSON } from './dynamic-json/project-pep-dynamic-jsons';
import { ProjectmspDynamicJSON } from './dynamic-json/project-msp-dynamic-jsons';
import { ProjectbspDynamicJSON } from './dynamic-json/project-bsp-dynamic-jsons';
import { ProjectappDynamicJSON } from './dynamic-json/project-app-dynamic-jsons';
import { ProductDynamicJSON } from './dynamic-json/products-dynamic-jsons';
import { LoginDynamicJSON } from './dynamic-json/login-dynamic-jsons';

export const sharedAngularModules = [CommonModule, ReactiveFormsModule, FormsModule];
export const sharedJson = [VendorDynamicJSON,UsermgtDynamicJSON,ProtocolDynamicJSON,ProjectpepJSON,ProjectmspDynamicJSON,ProjectbspDynamicJSON,ProjectappDynamicJSON,ProductDynamicJSON,LoginDynamicJSON,InventoryDynamicJSON]