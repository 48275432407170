import { CURRENTDATE } from '../app-constants';

export const VendorDynamicJSON = [
  // Add new vendor
  {
    jsonId: 74,
    control: [],
    head: '',
    name: 'main',
    id: 'main',
    wrapperType: 'REGULAR',
    fields: [
      {

      },
      {
        control: [
          {
            type: 'text',
            name: 'vendor_name',
            id: 'vendor_name',
            value: '',
            placeholder: '',
            multiline: false,
            classname: 'form-control ',
            regularExpression: '',
            disabled: false,
            required: true,
            requiredErrorMessage: 'This field is required',
            errorMessage: { noData: '' },
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Vendor Name',
            labelClass: 'form-label',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_935',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_276',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
      {
        control: [
          {
            type: 'dropdown',
            showBlankInPlaceholder: true,
            blankDataList: ['""', 'undefined', 'null'],
            name: 'vendor_inv_type',
            id: 'vendor_inv_type',
            validationButtonType: 'submit',
            classname: '',
            required: true,
            apiEndPoints: [
              {
                type: 'init',
                method: 'GET',
                url: 'vendor/inventory_type',
              },
            ],
            options: [],
            multiple: true,
            isClearable: true,
            isStatus: false,
            search: true,
            singleSelect: false,
            showLabel: 'value',
            errorMessage: { noData: 'This field is required' },
            value: '',
            imageUrl: '',
            disabled: false,
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Item Purchased',
            labelClass: 'form-label',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_656',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_306',
        fields: [],
        head: '',
        tabHeadClass: '',
      },
      {
        control: [
          {
            type: 'text',
            name: 'vendor_desc',
            id: 'vendor_desc',
            value: '',
            placeholder: '',
            multiline: true,
            classname: 'form-control textarea',
            regularExpression: '',
            disabled: false,
            required: true,
            requiredErrorMessage: 'This field is required',
            errorMessage: { noData: '' },
            validationButtonType: 'submit',
            rows: 0,
            columns: 0,
            onDemandError: false,
            customValidation: [],
            multiError: false,
            applicationError: '',
            wrapperClassName: '',
            showInputLabel: true,
            labelContent: 'Description',
            labelClass: 'form-label',
            isLabelAfter: false,
            showAdditionalInputContent: false,
            additionalInputContent: '',
            isAdditonalInputAfter: true,
            draggable: false,
          },
        ],
        type: '',
        name: 'wrapper_946',
        classname: 'form-group',
        wrapperType: 'BOX',
        id: 'wrapper_547',
        fields: [],
        head: '',
        tabHeadClass: '',
        draggable: false,
      },
    ],
    type: '',
    classname: '',
  },
  //vendor search
  {
    "jsonId": "75",
    "control": [],
    "head": "",
    "name": "main",
    "id": "main",
    "wrapperType": "REGULAR",
    "fields": [
      {
        "control": [],
        "type": "",
        "name": "wrapper_661",
        "classname": "row",
        "wrapperType": "BOX",
        "id": "wrapper_986",
        "fields": [
          {
            "control": [
              {
                "type": "text",
                "name": "vendor_name",
                "id": "vendor_name",
                "value": "",
                "placeholder": "",
                "multiline": false,
                "classname": "form-control ",
                "regularExpression": "",
                "disabled": false,
                "required": false,
                "errorMessage": {
                  "noData": "This field is required",
                  "regexFail": ""
                },
                "validationButtonType": "",
                "rows": 0,
                "columns": 0,
                "onDemandError": false,
                "customValidation": [],
                "multiError": false,
                "applicationError": "",
                "wrapperClassName": "",
                "requiredErrorMessage": "This field is required",
                "showInputLabel": true,
                "labelContent": "Vendor Name",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_519",
            "classname": "col-6",
            "wrapperType": "BOX",
            "id": "wrapper_252",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          },
          {
            "control": [
              {
                type: 'dropdown',
                showBlankInPlaceholder: true,
                blankDataList: ['""', 'undefined', 'null'],
                "name": "vendor_inv_type",
                "id": "vendor_inv_type",
                "validationButtonType": "",
                "classname": "",
                "required": false,
                "apiEndPoints": [
                  {
                    "type": "init",
                    "method": "GET",
                    "url": "vendor/inventory_type"
                  }
                ],
                "options": [],
                "multiple": true,
                "isClearable": true,
                "isStatus": false,
                "search": true,
                "singleSelect": false,
                "showLabel": "value",
                "errorMessage": {
                  "noData": "This field is required"
                },
                "value": "",
                "imageUrl": "",
                "disabled": false,
                "wrapperClassName": "",
                "showInputLabel": true,
                "labelContent": "Item Purchased",
                "labelClass": "form-label",
                "isLabelAfter": false,
                "showAdditionalInputContent": false,
                "additionalInputContent": "",
                "isAdditonalInputAfter": true,
                "draggable": false
              }
            ],
            "type": "",
            "name": "wrapper_656",
            "classname": "col-6",
            "wrapperType": "BOX",
            "id": "wrapper_306",
            "fields": [],
            "head": "",
            "tabHeadClass": ""
          }
        ],
        "head": "",
        "tabHeadClass": "",
        "draggable": false
      }
    ],
    "type": "",
    "classname": ""
  },]